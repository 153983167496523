import api from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import UIStore from "../../../../stores/UIStore";
import AuthStore from "../../../../stores/AuthStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { computed } from "mobx";
import { IFields } from "../../../../components/Select";

export default class UserTableStore extends PaginatedListStore<api.User> {
	private authStore: AuthStore;
	private uiStore: UIStore;
	public filterParam: string;
	public selectedEnterprise: api.Enterprise | null = null;
	private options: Record<string, any>;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.setSelectedEnterprise();
		this.options = {
			all: null,
			asc: api.FilterOrder.asc,
			desc: api.FilterOrder.desc,
		};
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
	}

	protected setSelectedEnterprise() {
		const selectedEnterprise = localStorage.getItem("@selectedEnterprise");
		if (selectedEnterprise) {
			this.selectedEnterprise = JSON.parse(selectedEnterprise);
		}
	}

	public changeFilterParams(filterParams: string) {
		this.filterParam = filterParams;
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.User[]> {
		return api.getAllCollaborators(
			this.selectedEnterprise && this.selectedEnterprise.id,
			{ pointsOrder: this.options[this.filterParam] },
			page,
		);
	}

	public cleanPoints = async (enterpriseId?: string) => {
		try {
			if (!enterpriseId) {
				this.uiStore.showSnackbar(Strings.error.default);
				return;
			}

			await api.cleanCollaboratorsPoints(enterpriseId);
			this.fetchPage(0);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};

	public deleteUser = async (id: string) => {
		try {
			await api.deleteCollaborator(id, this.selectedEnterprise && this.selectedEnterprise.id);
			this.fetchPage(0);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};

	@computed
	public get getFilterFields(): IFields[] {
		return [
			{
				name: Strings.filter.createDate,
				value: "all",
			},
			{
				name: Strings.filter.asc,
				value: "asc",
			},
			{
				name: Strings.filter.desc,
				value: "desc",
			},
		];
	}
}
