import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface Enterprise {
    id: string;
    name: string;
    cnpj: string;
    blockedAt: Date | null;
}

export interface NewEnterprise {
    name: string;
    cnpj: string;
}

export interface EditEnterprise {
    name: string;
    cnpj: string;
}

export interface EnterpriseFilter {
    blocked: boolean | null;
}

export interface AdminUser {
    id: string;
    name: string;
    image: Image | null;
    email: string;
    createdAt: Date;
    enterprise: Enterprise | null;
}

export interface NewAdminUser {
    name: string;
    email: string;
    image: UncertainImage | null;
    password: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
    image: UncertainImage | null;
}

export interface User {
    id: string;
    email: string;
    nick: string;
    name: string;
    image: Image | null;
    phone: string | null;
    cpf: string | null;
    birthdate: Date | null;
    createdAt: Date;
    weekdaysAvailable: Weekdays[];
    enterprise: Enterprise | null;
    points: number;
}

export interface NewCollaborator {
    email: string;
    nick: string;
    password: string;
    name: string;
    image: UncertainImage | null;
    phone: string;
    cpf: string | null;
    birthdate: Date;
    enterprise: Enterprise;
}

export interface EditCollaborator {
    email: string;
    nick: string;
    name: string;
    image: UncertainImage | null;
    phone: string;
    cpf: string | null;
    birthdate: Date;
    enterprise: Enterprise;
}

export interface CollaboratorsFilter {
    pointsOrder: FilterOrder | null;
}

export interface NewContact {
    name: string;
    phone: string;
    email: string;
    numberOfEmployees: number;
}

export interface Contact {
    id: string;
    name: string;
    phone: string;
    email: string;
    numberOfEmployees: number;
}

export interface Tag {
    id: string;
    name: string;
}

export interface LessonFilter {
    type: LessonType | null;
    tags: Tag[] | null;
    target: LessonTarget | null;
    watched: boolean | null;
}

export interface Lesson {
    id: string;
    url: string;
    title: string;
    description: string;
    type: LessonType;
    target: LessonTarget;
    image: Image | null;
    prerequisite: string;
    tags: Tag[];
    premium: boolean;
}

export interface LessonDailyType {
    done: boolean;
    suggestLesson: Lesson;
}

export interface NewEnterpriseAdminUser {
    name: string;
    email: string;
    image: UncertainImage | null;
    password: string;
    enterprise: Enterprise;
}

export interface EditEnterpriseAdminUser {
    name: string;
    email: string;
    image: UncertainImage | null;
    enterprise: Enterprise;
}

export interface HomeData {
    numberOfUsersEnterprise: number;
    numberOfUsersCollaborator: number;
}

export interface NewLesson {
    url: string;
    title: string;
    description: string;
    prerequisite: string;
    type: LessonType;
    image: UncertainImage | null;
    tags: Tag[];
    target: LessonTarget;
    premium: boolean;
}

export interface EditLesson {
    url: string;
    title: string;
    description: string;
    prerequisite: string;
    type: LessonType;
    image: UncertainImage | null;
    tags: Tag[];
    target: LessonTarget;
    premium: boolean;
}

export interface Level {
    id: string;
    level: number;
    minPoints: number;
    maxPoints: number;
}

export interface NewLevel {
    level: number;
    minPoints: number;
    maxPoints: number;
}

export interface EditLevel {
    level: number;
    minPoints: number;
    maxPoints: number;
}

export interface NewTag {
    name: string;
}

export interface EditTag {
    name: string;
}

export interface NewUser {
    email: string;
    nick: string;
    password: string;
    name: string;
    image: UncertainImage | null;
    phone: string | null;
    cpf: string | null;
    birthdate: Date | null;
}

export interface EditUser {
    email: string;
    nick: string;
    name: string;
    image: UncertainImage | null;
    phone: string | null;
    cpf: string | null;
    birthdate: Date | null;
}

export enum FilterOrder {
    desc = "desc",
    asc = "asc",
}

export function translateFilterOrder(enumFilterOrder: FilterOrder): string {
    switch (enumFilterOrder) {
        case FilterOrder.desc: {
            return strings ? strings["enum"]["FilterOrder"]["desc"] || FilterOrder.desc : FilterOrder.desc;
        }
        case FilterOrder.asc: {
            return strings ? strings["enum"]["FilterOrder"]["asc"] || FilterOrder.asc : FilterOrder.asc;
        }
    }
    return "";
}

export function allValuesFilterOrder(): FilterOrder[] {
    return [
        FilterOrder.desc,
        FilterOrder.asc,
    ];
}

export function allTranslatedValuesFilterOrder(): string[] {
    return [
        translateFilterOrder(FilterOrder.desc),
        translateFilterOrder(FilterOrder.asc),
    ];
}

export function allDisplayableValuesFilterOrder(): string[] {
    return allTranslatedValuesFilterOrder().sort();
}

export function valueFromTranslationFilterOrder(translation: string): FilterOrder {
    const index = allTranslatedValuesFilterOrder().indexOf(translation);
    return allValuesFilterOrder()[index] || FilterOrder.desc;
}

export enum Weekdays {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
}

export function translateWeekdays(enumWeekdays: Weekdays): string {
    switch (enumWeekdays) {
        case Weekdays.Sunday: {
            return strings ? strings["enum"]["Weekdays"]["Sunday"] || Weekdays.Sunday : Weekdays.Sunday;
        }
        case Weekdays.Monday: {
            return strings ? strings["enum"]["Weekdays"]["Monday"] || Weekdays.Monday : Weekdays.Monday;
        }
        case Weekdays.Tuesday: {
            return strings ? strings["enum"]["Weekdays"]["Tuesday"] || Weekdays.Tuesday : Weekdays.Tuesday;
        }
        case Weekdays.Wednesday: {
            return strings ? strings["enum"]["Weekdays"]["Wednesday"] || Weekdays.Wednesday : Weekdays.Wednesday;
        }
        case Weekdays.Thursday: {
            return strings ? strings["enum"]["Weekdays"]["Thursday"] || Weekdays.Thursday : Weekdays.Thursday;
        }
        case Weekdays.Friday: {
            return strings ? strings["enum"]["Weekdays"]["Friday"] || Weekdays.Friday : Weekdays.Friday;
        }
        case Weekdays.Saturday: {
            return strings ? strings["enum"]["Weekdays"]["Saturday"] || Weekdays.Saturday : Weekdays.Saturday;
        }
    }
    return "";
}

export function allValuesWeekdays(): Weekdays[] {
    return [
        Weekdays.Sunday,
        Weekdays.Monday,
        Weekdays.Tuesday,
        Weekdays.Wednesday,
        Weekdays.Thursday,
        Weekdays.Friday,
        Weekdays.Saturday,
    ];
}

export function allTranslatedValuesWeekdays(): string[] {
    return [
        translateWeekdays(Weekdays.Sunday),
        translateWeekdays(Weekdays.Monday),
        translateWeekdays(Weekdays.Tuesday),
        translateWeekdays(Weekdays.Wednesday),
        translateWeekdays(Weekdays.Thursday),
        translateWeekdays(Weekdays.Friday),
        translateWeekdays(Weekdays.Saturday),
    ];
}

export function allDisplayableValuesWeekdays(): string[] {
    return allTranslatedValuesWeekdays().sort();
}

export function valueFromTranslationWeekdays(translation: string): Weekdays {
    const index = allTranslatedValuesWeekdays().indexOf(translation);
    return allValuesWeekdays()[index] || Weekdays.Sunday;
}

export enum NotificationType {
    dailyLesson = "dailyLesson",
}

export function translateNotificationType(enumNotificationType: NotificationType): string {
    switch (enumNotificationType) {
        case NotificationType.dailyLesson: {
            return strings ? strings["enum"]["NotificationType"]["dailyLesson"] || NotificationType.dailyLesson : NotificationType.dailyLesson;
        }
    }
    return "";
}

export function allValuesNotificationType(): NotificationType[] {
    return [
        NotificationType.dailyLesson,
    ];
}

export function allTranslatedValuesNotificationType(): string[] {
    return [
        translateNotificationType(NotificationType.dailyLesson),
    ];
}

export function allDisplayableValuesNotificationType(): string[] {
    return allTranslatedValuesNotificationType().sort();
}

export function valueFromTranslationNotificationType(translation: string): NotificationType {
    const index = allTranslatedValuesNotificationType().indexOf(translation);
    return allValuesNotificationType()[index] || NotificationType.dailyLesson;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum LessonType {
    preparatory = "preparatory",
    compensatory = "compensatory",
    relaxation = "relaxation",
}

export function translateLessonType(enumLessonType: LessonType): string {
    switch (enumLessonType) {
        case LessonType.preparatory: {
            return strings ? strings["enum"]["LessonType"]["preparatory"] || LessonType.preparatory : LessonType.preparatory;
        }
        case LessonType.compensatory: {
            return strings ? strings["enum"]["LessonType"]["compensatory"] || LessonType.compensatory : LessonType.compensatory;
        }
        case LessonType.relaxation: {
            return strings ? strings["enum"]["LessonType"]["relaxation"] || LessonType.relaxation : LessonType.relaxation;
        }
    }
    return "";
}

export function allValuesLessonType(): LessonType[] {
    return [
        LessonType.preparatory,
        LessonType.compensatory,
        LessonType.relaxation,
    ];
}

export function allTranslatedValuesLessonType(): string[] {
    return [
        translateLessonType(LessonType.preparatory),
        translateLessonType(LessonType.compensatory),
        translateLessonType(LessonType.relaxation),
    ];
}

export function allDisplayableValuesLessonType(): string[] {
    return allTranslatedValuesLessonType().sort();
}

export function valueFromTranslationLessonType(translation: string): LessonType {
    const index = allTranslatedValuesLessonType().indexOf(translation);
    return allValuesLessonType()[index] || LessonType.preparatory;
}

export enum LessonTarget {
    general = "general",
    specific = "specific",
}

export function translateLessonTarget(enumLessonTarget: LessonTarget): string {
    switch (enumLessonTarget) {
        case LessonTarget.general: {
            return strings ? strings["enum"]["LessonTarget"]["general"] || LessonTarget.general : LessonTarget.general;
        }
        case LessonTarget.specific: {
            return strings ? strings["enum"]["LessonTarget"]["specific"] || LessonTarget.specific : LessonTarget.specific;
        }
    }
    return "";
}

export function allValuesLessonTarget(): LessonTarget[] {
    return [
        LessonTarget.general,
        LessonTarget.specific,
    ];
}

export function allTranslatedValuesLessonTarget(): string[] {
    return [
        translateLessonTarget(LessonTarget.general),
        translateLessonTarget(LessonTarget.specific),
    ];
}

export function allDisplayableValuesLessonTarget(): string[] {
    return allTranslatedValuesLessonTarget().sort();
}

export function valueFromTranslationLessonTarget(translation: string): LessonTarget {
    const index = allTranslatedValuesLessonTarget().indexOf(translation);
    return allValuesLessonTarget()[index] || LessonTarget.general;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    NickAlreadyRegistered = "NickAlreadyRegistered",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    NotLoggedIn = "NotLoggedIn",
    BlockedEnterprise = "BlockedEnterprise",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.NickAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyRegistered"] || ErrorType.NickAlreadyRegistered : ErrorType.NickAlreadyRegistered;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.BlockedEnterprise: {
            return strings ? strings["enum"]["ErrorType"]["BlockedEnterprise"] || ErrorType.BlockedEnterprise : ErrorType.BlockedEnterprise;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.NickAlreadyRegistered,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.NotLoggedIn,
        ErrorType.BlockedEnterprise,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.NickAlreadyRegistered),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.BlockedEnterprise),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getEnterprise(id: string, progress?: (progress: number) => void): Promise<Enterprise> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getEnterprise", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        cnpj: ret.cnpj,
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function createEnterprise(newEnterprise: NewEnterprise, progress?: (progress: number) => void): Promise<Enterprise> {
    const args = {
        newEnterprise: {
            name: newEnterprise.name,
            cnpj: newEnterprise.cnpj,
        },
    };
    const ret = await makeRequest({name: "createEnterprise", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        cnpj: ret.cnpj,
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function editEnterprise(id: string, editEnterprise: EditEnterprise, progress?: (progress: number) => void): Promise<Enterprise> {
    const args = {
        id: id,
        editEnterprise: {
            name: editEnterprise.name,
            cnpj: editEnterprise.cnpj,
        },
    };
    const ret = await makeRequest({name: "editEnterprise", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        cnpj: ret.cnpj,
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function deleteEnterprise(id: string, progress?: (progress: number) => void): Promise<Enterprise> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteEnterprise", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        cnpj: ret.cnpj,
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function getAllEnterprises(filter: EnterpriseFilter, pageOffset: number, progress?: (progress: number) => void): Promise<Enterprise[]> {
    const args = {
        filter: {
            blocked: filter.blocked === null || filter.blocked === undefined ? null : !!filter.blocked,
        },
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllEnterprises", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        cnpj: e.cnpj,
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
    }));
}

export async function getAllEnterprisesByName(enterpriseName: string, progress?: (progress: number) => void): Promise<Enterprise[]> {
    const args = {
        enterpriseName: enterpriseName,
    };
    const ret = await makeRequest({name: "getAllEnterprisesByName", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        cnpj: e.cnpj,
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
    }));
}

export async function blockEnterprise(id: string, progress?: (progress: number) => void): Promise<Enterprise> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "blockEnterprise", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        cnpj: ret.cnpj,
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function unblockEnterprise(id: string, progress?: (progress: number) => void): Promise<Enterprise> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "unblockEnterprise", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        cnpj: ret.cnpj,
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
    };
}

export async function getAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            name: newAdminUser.name,
            email: newAdminUser.email,
            image: newAdminUser.image === null || newAdminUser.image === undefined ? null : {
                bytes: newAdminUser.image.bytes === null || newAdminUser.image.bytes === undefined ? null : newAdminUser.image.bytes.toString("base64"),
                image: newAdminUser.image.image === null || newAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: newAdminUser.image.image.thumb.width || 0,
                        height: newAdminUser.image.image.thumb.height || 0,
                        url: newAdminUser.image.image.thumb.url,
                    },
                    width: newAdminUser.image.image.width || 0,
                    height: newAdminUser.image.image.height || 0,
                    url: newAdminUser.image.image.url,
                },
            },
            password: newAdminUser.password,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            image: editAdminUser.image === null || editAdminUser.image === undefined ? null : {
                bytes: editAdminUser.image.bytes === null || editAdminUser.image.bytes === undefined ? null : editAdminUser.image.bytes.toString("base64"),
                image: editAdminUser.image.image === null || editAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editAdminUser.image.image.thumb.width || 0,
                        height: editAdminUser.image.image.thumb.height || 0,
                        url: editAdminUser.image.image.thumb.url,
                    },
                    width: editAdminUser.image.image.width || 0,
                    height: editAdminUser.image.image.height || 0,
                    url: editAdminUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function deleteAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function getAllAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        email: e.email,
        createdAt: new Date(e.createdAt + "Z"),
        enterprise: e.enterprise === null || e.enterprise === undefined ? null : {
            id: e.enterprise.id,
            name: e.enterprise.name,
            cnpj: e.enterprise.cnpj,
            blockedAt: e.enterprise.blockedAt === null || e.enterprise.blockedAt === undefined ? null : new Date(parseInt(e.enterprise.blockedAt.split("-")[0], 10), parseInt(e.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(e.enterprise.blockedAt.split("-")[2], 10)),
        },
    }));
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function getCollaborator(id: string, enterpriseId: string | null, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        enterpriseId: enterpriseId === null || enterpriseId === undefined ? null : enterpriseId,
    };
    const ret = await makeRequest({name: "getCollaborator", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        nick: ret.nick,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
        weekdaysAvailable: ret.weekdaysAvailable.map((e: any) => e),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: ret.points || 0,
    };
}

export async function getAllCollaborators(enterpriseId: string | null, filter: CollaboratorsFilter, pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        enterpriseId: enterpriseId === null || enterpriseId === undefined ? null : enterpriseId,
        filter: {
            pointsOrder: filter.pointsOrder === null || filter.pointsOrder === undefined ? null : filter.pointsOrder,
        },
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllCollaborators", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        nick: e.nick,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(parseInt(e.birthdate.split("-")[0], 10), parseInt(e.birthdate.split("-")[1], 10) - 1, parseInt(e.birthdate.split("-")[2], 10)),
        createdAt: new Date(e.createdAt + "Z"),
        weekdaysAvailable: e.weekdaysAvailable.map((e: any) => e),
        enterprise: e.enterprise === null || e.enterprise === undefined ? null : {
            id: e.enterprise.id,
            name: e.enterprise.name,
            cnpj: e.enterprise.cnpj,
            blockedAt: e.enterprise.blockedAt === null || e.enterprise.blockedAt === undefined ? null : new Date(parseInt(e.enterprise.blockedAt.split("-")[0], 10), parseInt(e.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(e.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: e.points || 0,
    }));
}

export async function editCollaborator(id: string, editCollaborator: EditCollaborator, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        editCollaborator: {
            email: editCollaborator.email,
            nick: editCollaborator.nick,
            name: editCollaborator.name,
            image: editCollaborator.image === null || editCollaborator.image === undefined ? null : {
                bytes: editCollaborator.image.bytes === null || editCollaborator.image.bytes === undefined ? null : editCollaborator.image.bytes.toString("base64"),
                image: editCollaborator.image.image === null || editCollaborator.image.image === undefined ? null : {
                    thumb: {
                        width: editCollaborator.image.image.thumb.width || 0,
                        height: editCollaborator.image.image.thumb.height || 0,
                        url: editCollaborator.image.image.thumb.url,
                    },
                    width: editCollaborator.image.image.width || 0,
                    height: editCollaborator.image.image.height || 0,
                    url: editCollaborator.image.image.url,
                },
            },
            phone: editCollaborator.phone,
            cpf: editCollaborator.cpf === null || editCollaborator.cpf === undefined ? null : editCollaborator.cpf,
            birthdate: typeof(editCollaborator.birthdate) === "string" ? new Date(new Date(editCollaborator.birthdate).getTime() - new Date(editCollaborator.birthdate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editCollaborator.birthdate.getTime() - editCollaborator.birthdate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            enterprise: {
                id: editCollaborator.enterprise.id,
                name: editCollaborator.enterprise.name,
                cnpj: editCollaborator.enterprise.cnpj,
                blockedAt: editCollaborator.enterprise.blockedAt === null || editCollaborator.enterprise.blockedAt === undefined ? null : typeof(editCollaborator.enterprise.blockedAt) === "string" ? new Date(new Date(editCollaborator.enterprise.blockedAt).getTime() - new Date(editCollaborator.enterprise.blockedAt).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editCollaborator.enterprise.blockedAt.getTime() - editCollaborator.enterprise.blockedAt.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
        },
    };
    const ret = await makeRequest({name: "editCollaborator", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        nick: ret.nick,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
        weekdaysAvailable: ret.weekdaysAvailable.map((e: any) => e),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: ret.points || 0,
    };
}

export async function createCollaborator(newCollaborator: NewCollaborator, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newCollaborator: {
            email: newCollaborator.email,
            nick: newCollaborator.nick,
            password: newCollaborator.password,
            name: newCollaborator.name,
            image: newCollaborator.image === null || newCollaborator.image === undefined ? null : {
                bytes: newCollaborator.image.bytes === null || newCollaborator.image.bytes === undefined ? null : newCollaborator.image.bytes.toString("base64"),
                image: newCollaborator.image.image === null || newCollaborator.image.image === undefined ? null : {
                    thumb: {
                        width: newCollaborator.image.image.thumb.width || 0,
                        height: newCollaborator.image.image.thumb.height || 0,
                        url: newCollaborator.image.image.thumb.url,
                    },
                    width: newCollaborator.image.image.width || 0,
                    height: newCollaborator.image.image.height || 0,
                    url: newCollaborator.image.image.url,
                },
            },
            phone: newCollaborator.phone,
            cpf: newCollaborator.cpf === null || newCollaborator.cpf === undefined ? null : newCollaborator.cpf,
            birthdate: typeof(newCollaborator.birthdate) === "string" ? new Date(new Date(newCollaborator.birthdate).getTime() - new Date(newCollaborator.birthdate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newCollaborator.birthdate.getTime() - newCollaborator.birthdate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            enterprise: {
                id: newCollaborator.enterprise.id,
                name: newCollaborator.enterprise.name,
                cnpj: newCollaborator.enterprise.cnpj,
                blockedAt: newCollaborator.enterprise.blockedAt === null || newCollaborator.enterprise.blockedAt === undefined ? null : typeof(newCollaborator.enterprise.blockedAt) === "string" ? new Date(new Date(newCollaborator.enterprise.blockedAt).getTime() - new Date(newCollaborator.enterprise.blockedAt).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newCollaborator.enterprise.blockedAt.getTime() - newCollaborator.enterprise.blockedAt.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
        },
    };
    const ret = await makeRequest({name: "createCollaborator", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        nick: ret.nick,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
        weekdaysAvailable: ret.weekdaysAvailable.map((e: any) => e),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: ret.points || 0,
    };
}

export async function deleteCollaborator(id: string, enterpriseId: string | null, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        enterpriseId: enterpriseId === null || enterpriseId === undefined ? null : enterpriseId,
    };
    const ret = await makeRequest({name: "deleteCollaborator", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        nick: ret.nick,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
        weekdaysAvailable: ret.weekdaysAvailable.map((e: any) => e),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: ret.points || 0,
    };
}

export async function cleanCollaboratorsPoints(enterpriseId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        enterpriseId: enterpriseId,
    };
    await makeRequest({name: "cleanCollaboratorsPoints", args, progress});
    return undefined;
}

export async function getContact(id: string, progress?: (progress: number) => void): Promise<Contact> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getContact", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        phone: ret.phone,
        email: ret.email,
        numberOfEmployees: ret.numberOfEmployees || 0,
    };
}

export async function getAllContact(pageOffset: number, progress?: (progress: number) => void): Promise<Contact[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllContact", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        phone: e.phone,
        email: e.email,
        numberOfEmployees: e.numberOfEmployees || 0,
    }));
}

export async function getEnterpriseAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getEnterpriseAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function getAllEnterpriseAdminUsers(enterpriseId: string | null, pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        enterpriseId: enterpriseId === null || enterpriseId === undefined ? null : enterpriseId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllEnterpriseAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        email: e.email,
        createdAt: new Date(e.createdAt + "Z"),
        enterprise: e.enterprise === null || e.enterprise === undefined ? null : {
            id: e.enterprise.id,
            name: e.enterprise.name,
            cnpj: e.enterprise.cnpj,
            blockedAt: e.enterprise.blockedAt === null || e.enterprise.blockedAt === undefined ? null : new Date(parseInt(e.enterprise.blockedAt.split("-")[0], 10), parseInt(e.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(e.enterprise.blockedAt.split("-")[2], 10)),
        },
    }));
}

export async function createEnterpriseAdminUsers(newEnterpriseAdminUser: NewEnterpriseAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newEnterpriseAdminUser: {
            name: newEnterpriseAdminUser.name,
            email: newEnterpriseAdminUser.email,
            image: newEnterpriseAdminUser.image === null || newEnterpriseAdminUser.image === undefined ? null : {
                bytes: newEnterpriseAdminUser.image.bytes === null || newEnterpriseAdminUser.image.bytes === undefined ? null : newEnterpriseAdminUser.image.bytes.toString("base64"),
                image: newEnterpriseAdminUser.image.image === null || newEnterpriseAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: newEnterpriseAdminUser.image.image.thumb.width || 0,
                        height: newEnterpriseAdminUser.image.image.thumb.height || 0,
                        url: newEnterpriseAdminUser.image.image.thumb.url,
                    },
                    width: newEnterpriseAdminUser.image.image.width || 0,
                    height: newEnterpriseAdminUser.image.image.height || 0,
                    url: newEnterpriseAdminUser.image.image.url,
                },
            },
            password: newEnterpriseAdminUser.password,
            enterprise: {
                id: newEnterpriseAdminUser.enterprise.id,
                name: newEnterpriseAdminUser.enterprise.name,
                cnpj: newEnterpriseAdminUser.enterprise.cnpj,
                blockedAt: newEnterpriseAdminUser.enterprise.blockedAt === null || newEnterpriseAdminUser.enterprise.blockedAt === undefined ? null : typeof(newEnterpriseAdminUser.enterprise.blockedAt) === "string" ? new Date(new Date(newEnterpriseAdminUser.enterprise.blockedAt).getTime() - new Date(newEnterpriseAdminUser.enterprise.blockedAt).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newEnterpriseAdminUser.enterprise.blockedAt.getTime() - newEnterpriseAdminUser.enterprise.blockedAt.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
        },
    };
    const ret = await makeRequest({name: "createEnterpriseAdminUsers", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function editEnterpriseAdminUser(id: string, editEnterpriseAdminUser: EditEnterpriseAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editEnterpriseAdminUser: {
            name: editEnterpriseAdminUser.name,
            email: editEnterpriseAdminUser.email,
            image: editEnterpriseAdminUser.image === null || editEnterpriseAdminUser.image === undefined ? null : {
                bytes: editEnterpriseAdminUser.image.bytes === null || editEnterpriseAdminUser.image.bytes === undefined ? null : editEnterpriseAdminUser.image.bytes.toString("base64"),
                image: editEnterpriseAdminUser.image.image === null || editEnterpriseAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editEnterpriseAdminUser.image.image.thumb.width || 0,
                        height: editEnterpriseAdminUser.image.image.thumb.height || 0,
                        url: editEnterpriseAdminUser.image.image.thumb.url,
                    },
                    width: editEnterpriseAdminUser.image.image.width || 0,
                    height: editEnterpriseAdminUser.image.image.height || 0,
                    url: editEnterpriseAdminUser.image.image.url,
                },
            },
            enterprise: {
                id: editEnterpriseAdminUser.enterprise.id,
                name: editEnterpriseAdminUser.enterprise.name,
                cnpj: editEnterpriseAdminUser.enterprise.cnpj,
                blockedAt: editEnterpriseAdminUser.enterprise.blockedAt === null || editEnterpriseAdminUser.enterprise.blockedAt === undefined ? null : typeof(editEnterpriseAdminUser.enterprise.blockedAt) === "string" ? new Date(new Date(editEnterpriseAdminUser.enterprise.blockedAt).getTime() - new Date(editEnterpriseAdminUser.enterprise.blockedAt).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editEnterpriseAdminUser.enterprise.blockedAt.getTime() - editEnterpriseAdminUser.enterprise.blockedAt.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            },
        },
    };
    const ret = await makeRequest({name: "editEnterpriseAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function deleteEnterpriseAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteEnterpriseAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        createdAt: new Date(ret.createdAt + "Z"),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
    };
}

export async function getHomeData(enterpriseId: string | null, progress?: (progress: number) => void): Promise<HomeData> {
    const args = {
        enterpriseId: enterpriseId === null || enterpriseId === undefined ? null : enterpriseId,
    };
    const ret = await makeRequest({name: "getHomeData", args, progress});
    return {
        numberOfUsersEnterprise: ret.numberOfUsersEnterprise || 0,
        numberOfUsersCollaborator: ret.numberOfUsersCollaborator || 0,
    };
}

export async function getLesson(id: string, progress?: (progress: number) => void): Promise<Lesson> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getLesson", args, progress});
    return {
        id: ret.id,
        url: ret.url,
        title: ret.title,
        description: ret.description,
        type: ret.type,
        target: ret.target,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        prerequisite: ret.prerequisite,
        tags: ret.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        premium: !!ret.premium,
    };
}

export async function createLesson(newLesson: NewLesson, progress?: (progress: number) => void): Promise<Lesson> {
    const args = {
        newLesson: {
            url: newLesson.url,
            title: newLesson.title,
            description: newLesson.description,
            prerequisite: newLesson.prerequisite,
            type: newLesson.type,
            image: newLesson.image === null || newLesson.image === undefined ? null : {
                bytes: newLesson.image.bytes === null || newLesson.image.bytes === undefined ? null : newLesson.image.bytes.toString("base64"),
                image: newLesson.image.image === null || newLesson.image.image === undefined ? null : {
                    thumb: {
                        width: newLesson.image.image.thumb.width || 0,
                        height: newLesson.image.image.thumb.height || 0,
                        url: newLesson.image.image.thumb.url,
                    },
                    width: newLesson.image.image.width || 0,
                    height: newLesson.image.image.height || 0,
                    url: newLesson.image.image.url,
                },
            },
            tags: newLesson.tags.map(e => ({
                id: e.id,
                name: e.name,
            })),
            target: newLesson.target,
            premium: !!newLesson.premium,
        },
    };
    const ret = await makeRequest({name: "createLesson", args, progress});
    return {
        id: ret.id,
        url: ret.url,
        title: ret.title,
        description: ret.description,
        type: ret.type,
        target: ret.target,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        prerequisite: ret.prerequisite,
        tags: ret.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        premium: !!ret.premium,
    };
}

export async function editLesson(id: string, editLesson: EditLesson, progress?: (progress: number) => void): Promise<Lesson> {
    const args = {
        id: id,
        editLesson: {
            url: editLesson.url,
            title: editLesson.title,
            description: editLesson.description,
            prerequisite: editLesson.prerequisite,
            type: editLesson.type,
            image: editLesson.image === null || editLesson.image === undefined ? null : {
                bytes: editLesson.image.bytes === null || editLesson.image.bytes === undefined ? null : editLesson.image.bytes.toString("base64"),
                image: editLesson.image.image === null || editLesson.image.image === undefined ? null : {
                    thumb: {
                        width: editLesson.image.image.thumb.width || 0,
                        height: editLesson.image.image.thumb.height || 0,
                        url: editLesson.image.image.thumb.url,
                    },
                    width: editLesson.image.image.width || 0,
                    height: editLesson.image.image.height || 0,
                    url: editLesson.image.image.url,
                },
            },
            tags: editLesson.tags.map(e => ({
                id: e.id,
                name: e.name,
            })),
            target: editLesson.target,
            premium: !!editLesson.premium,
        },
    };
    const ret = await makeRequest({name: "editLesson", args, progress});
    return {
        id: ret.id,
        url: ret.url,
        title: ret.title,
        description: ret.description,
        type: ret.type,
        target: ret.target,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        prerequisite: ret.prerequisite,
        tags: ret.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        premium: !!ret.premium,
    };
}

export async function deleteLesson(id: string, progress?: (progress: number) => void): Promise<Lesson> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteLesson", args, progress});
    return {
        id: ret.id,
        url: ret.url,
        title: ret.title,
        description: ret.description,
        type: ret.type,
        target: ret.target,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        prerequisite: ret.prerequisite,
        tags: ret.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        premium: !!ret.premium,
    };
}

export async function getAllLessons(pageOffset: number, progress?: (progress: number) => void): Promise<Lesson[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllLessons", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        url: e.url,
        title: e.title,
        description: e.description,
        type: e.type,
        target: e.target,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        prerequisite: e.prerequisite,
        tags: e.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        premium: !!e.premium,
    }));
}

export async function getLevel(id: string, progress?: (progress: number) => void): Promise<Level> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getLevel", args, progress});
    return {
        id: ret.id,
        level: ret.level || 0,
        minPoints: ret.minPoints || 0,
        maxPoints: ret.maxPoints || 0,
    };
}

export async function createLevel(newLevel: NewLevel, progress?: (progress: number) => void): Promise<Level> {
    const args = {
        newLevel: {
            level: newLevel.level || 0,
            minPoints: newLevel.minPoints || 0,
            maxPoints: newLevel.maxPoints || 0,
        },
    };
    const ret = await makeRequest({name: "createLevel", args, progress});
    return {
        id: ret.id,
        level: ret.level || 0,
        minPoints: ret.minPoints || 0,
        maxPoints: ret.maxPoints || 0,
    };
}

export async function editLevel(id: string, editLevel: EditLevel, progress?: (progress: number) => void): Promise<Level> {
    const args = {
        id: id,
        editLevel: {
            level: editLevel.level || 0,
            minPoints: editLevel.minPoints || 0,
            maxPoints: editLevel.maxPoints || 0,
        },
    };
    const ret = await makeRequest({name: "editLevel", args, progress});
    return {
        id: ret.id,
        level: ret.level || 0,
        minPoints: ret.minPoints || 0,
        maxPoints: ret.maxPoints || 0,
    };
}

export async function getAllLevels(pageOffset: number, progress?: (progress: number) => void): Promise<Level[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllLevels", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        level: e.level || 0,
        minPoints: e.minPoints || 0,
        maxPoints: e.maxPoints || 0,
    }));
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getTag(id: string, progress?: (progress: number) => void): Promise<Tag> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getTag", args, progress});
    return {
        id: ret.id,
        name: ret.name,
    };
}

export async function createTag(newTag: NewTag, progress?: (progress: number) => void): Promise<Tag> {
    const args = {
        newTag: {
            name: newTag.name,
        },
    };
    const ret = await makeRequest({name: "createTag", args, progress});
    return {
        id: ret.id,
        name: ret.name,
    };
}

export async function editTag(id: string, editTag: EditTag, progress?: (progress: number) => void): Promise<Tag> {
    const args = {
        id: id,
        editTag: {
            name: editTag.name,
        },
    };
    const ret = await makeRequest({name: "editTag", args, progress});
    return {
        id: ret.id,
        name: ret.name,
    };
}

export async function deleteTag(id: string, progress?: (progress: number) => void): Promise<Tag> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteTag", args, progress});
    return {
        id: ret.id,
        name: ret.name,
    };
}

export async function getAllTags(pageOffset: number, progress?: (progress: number) => void): Promise<Tag[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllTags", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
    }));
}

export async function getAllTagsByName(tagName: string, progress?: (progress: number) => void): Promise<Tag[]> {
    const args = {
        tagName: tagName,
    };
    const ret = await makeRequest({name: "getAllTagsByName", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
    }));
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        nick: ret.nick,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
        weekdaysAvailable: ret.weekdaysAvailable.map((e: any) => e),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: ret.points || 0,
    };
}

export async function getAllUsers(pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        nick: e.nick,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(parseInt(e.birthdate.split("-")[0], 10), parseInt(e.birthdate.split("-")[1], 10) - 1, parseInt(e.birthdate.split("-")[2], 10)),
        createdAt: new Date(e.createdAt + "Z"),
        weekdaysAvailable: e.weekdaysAvailable.map((e: any) => e),
        enterprise: e.enterprise === null || e.enterprise === undefined ? null : {
            id: e.enterprise.id,
            name: e.enterprise.name,
            cnpj: e.enterprise.cnpj,
            blockedAt: e.enterprise.blockedAt === null || e.enterprise.blockedAt === undefined ? null : new Date(parseInt(e.enterprise.blockedAt.split("-")[0], 10), parseInt(e.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(e.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: e.points || 0,
    }));
}

export async function createUser(newUSer: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUSer: {
            email: newUSer.email,
            nick: newUSer.nick,
            password: newUSer.password,
            name: newUSer.name,
            image: newUSer.image === null || newUSer.image === undefined ? null : {
                bytes: newUSer.image.bytes === null || newUSer.image.bytes === undefined ? null : newUSer.image.bytes.toString("base64"),
                image: newUSer.image.image === null || newUSer.image.image === undefined ? null : {
                    thumb: {
                        width: newUSer.image.image.thumb.width || 0,
                        height: newUSer.image.image.thumb.height || 0,
                        url: newUSer.image.image.thumb.url,
                    },
                    width: newUSer.image.image.width || 0,
                    height: newUSer.image.image.height || 0,
                    url: newUSer.image.image.url,
                },
            },
            phone: newUSer.phone === null || newUSer.phone === undefined ? null : newUSer.phone,
            cpf: newUSer.cpf === null || newUSer.cpf === undefined ? null : newUSer.cpf,
            birthdate: newUSer.birthdate === null || newUSer.birthdate === undefined ? null : typeof(newUSer.birthdate) === "string" ? new Date(new Date(newUSer.birthdate).getTime() - new Date(newUSer.birthdate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newUSer.birthdate.getTime() - newUSer.birthdate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        nick: ret.nick,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
        weekdaysAvailable: ret.weekdaysAvailable.map((e: any) => e),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: ret.points || 0,
    };
}

export async function editUser(id: string, editUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        editUser: {
            email: editUser.email,
            nick: editUser.nick,
            name: editUser.name,
            image: editUser.image === null || editUser.image === undefined ? null : {
                bytes: editUser.image.bytes === null || editUser.image.bytes === undefined ? null : editUser.image.bytes.toString("base64"),
                image: editUser.image.image === null || editUser.image.image === undefined ? null : {
                    thumb: {
                        width: editUser.image.image.thumb.width || 0,
                        height: editUser.image.image.thumb.height || 0,
                        url: editUser.image.image.thumb.url,
                    },
                    width: editUser.image.image.width || 0,
                    height: editUser.image.image.height || 0,
                    url: editUser.image.image.url,
                },
            },
            phone: editUser.phone === null || editUser.phone === undefined ? null : editUser.phone,
            cpf: editUser.cpf === null || editUser.cpf === undefined ? null : editUser.cpf,
            birthdate: editUser.birthdate === null || editUser.birthdate === undefined ? null : typeof(editUser.birthdate) === "string" ? new Date(new Date(editUser.birthdate).getTime() - new Date(editUser.birthdate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editUser.birthdate.getTime() - editUser.birthdate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        nick: ret.nick,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
        weekdaysAvailable: ret.weekdaysAvailable.map((e: any) => e),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: ret.points || 0,
    };
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        nick: ret.nick,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf === null || ret.cpf === undefined ? null : ret.cpf,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(parseInt(ret.birthdate.split("-")[0], 10), parseInt(ret.birthdate.split("-")[1], 10) - 1, parseInt(ret.birthdate.split("-")[2], 10)),
        createdAt: new Date(ret.createdAt + "Z"),
        weekdaysAvailable: ret.weekdaysAvailable.map((e: any) => e),
        enterprise: ret.enterprise === null || ret.enterprise === undefined ? null : {
            id: ret.enterprise.id,
            name: ret.enterprise.name,
            cnpj: ret.enterprise.cnpj,
            blockedAt: ret.enterprise.blockedAt === null || ret.enterprise.blockedAt === undefined ? null : new Date(parseInt(ret.enterprise.blockedAt.split("-")[0], 10), parseInt(ret.enterprise.blockedAt.split("-")[1], 10) - 1, parseInt(ret.enterprise.blockedAt.split("-")[2], 10)),
        },
        points: ret.points || 0,
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
