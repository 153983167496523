import LocalizedStrings from "localized-strings";

// MARK: Implementation
const Strings = new LocalizedStrings({
	ptBR: {
		enum: {
			LessonType: {
				preparatory: "Preparatório",
				compensatory: "Compensatório",
				relaxation: "Relaxamento",
			},
			LessonTarget: {
				general: "Geral",
				specific: "Específico",
			},
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
		},
		fields: {
			id: "id",
			archive: "Arquivar",
			document: "Documentação",
			type: "Tipo",
			slug: "slug",
			image: "Imagem",
			url: "Url",
			tags: "Tags",
			enterprise: "Empresa",
			point: "Pontos",
			pointsOrder: "Ordenação por",
			prerequisite: "Pré-requisito",
			minAmount: "Quantidade miníma",
			diameter: "Diâmetro",
			images: "Imagens",
			phone: "Telefone",
			price: "Preço",
			productionTime: "Tempo de produção",
			whatsapp: "Whatsapp",
			category: "Categoria",
			name: "Nome",
			cnpj: "CNPJ",
			minPoints: "Mínimo de pontos",
			level: "Nível",
			maxPoints: "Máximo de pontos",
			nick: "Apelido",
			birthdate: "Data de Nascimento",
			cpf: "CPF",
			seeMore: "Veja mais",
			exit: "Sair",
			notRegistered: "Não cadastrado",
			save: "Salvar",
			email: "Email",
			numberOfEmployees: "Número de colaboradores",
			token: "Token",
			account: "Conta",
			text: "Texto",
			message: "Mensagem",
			// tslint:disable-next-line:no-hardcoded-credentials
			password: "Senha",
			recovery: "Recuperar Senha",
			title: "Título",
			description: "Descrição",
			createdAt: "Criado em",
			block: "Data de bloqueio",
			user: "Usuário",
			availability: "Aula disponível para",
		},
		components: {
			table: {
				noMoreResults: "Sem resultados",
				create: "Criar",
				delete: "Deletar",
				details: "Detalhes",
				edit: "Editar",
				export: "Exportar",
				accept: "Aceitar",
				reject: "Reject",
				fallbackData: "Não há dados correspondentes",
				editAndDelete: "Editar / Deletar",
				detailsAndDelete: "Detalhes / Deletar",
				detailsAndEdit: "Detalhes / Editar",
				blockAndEditAndDelete: "Editar / Bloquear / Deletar",
				detailsAndblockAndEditAndDelete:
					"Vizualizar / Editar / Bloquear / Deletar",
				detailsAndEditAndDelete: "Editar / Detalhes / Deletar",
				AcceptAndReject: "Aceitar / Recusar",
				AcceptAndRejectaandDetails: "Detalhes / Aceitar / Recusar ",
				Active: "Ativo",
				deletedSuccessfully: "Deletado com sucesso",

				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},

				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
			},
			datePicker: {
				format: "dd/MM/yyyy",
				invalidDate: "Data inválida",
			},
		},
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logoutDialog: {
			title: "Logout",
			message: "Tem certeza que deseja sair?",
		},
		filter: {
			all: "Todos",
			createDate: "Data de criação",
			blockeds: "Bloqueados",
			asc: "Menos Pontos",
			desc: "Mais pontos",
			avaliable: "Disponíveis",
		},
		dialogs: {
			deleteContact: (name: string) => ({
				title: "Apagar Contato",
				message:
					"Tem certeza que deseja apagar o contato " +
					name +
					" ?, essa operação não pode ser revertida.",
			}),
			cleanPoints: {
				title: (name?: string) => `Zerar pontos de ${name}`,
				message: "Deseja mesmo zerar os pontos de todos os usuários colaboradores?",
			},
			delete: {
				title: "Confirmação de exclusão.",
				message: "Deseja mesmo excluir este usuário?",
			},
			operationHasBeenASuccess: "Operação realizada com sucesso.",
		},
		loading: "Carregando",
		hiAdmin: "Olá, Administrador(a)",
		error: {
			needLongerText: (numOfChars: number) =>
				"Precisa ser maior que " + numOfChars + " caracteres.",
			invalidEmail: "Email inválido",
			invalidPhone: "Telefone inválido",
			stillLoading: "Ainda Carregando, aguarde.",
			nameIsEmpty: "O nome está vazio",
			default: "Houve um erro, tente novamente mais tarde.",
			audioError: "Para cadastrar um treino insira um audio.",
			emptyDate: "Houve um erro, data incorreta",
			mustWaitWhileStillLoading: "Ainda em carregamento, aguarde um momento.",
			missingImage: "É necessario anexar a imagem",
			missingDate: "Insira uma data.",
			missingEnterprise: "Selecione uma empresa.",
			invalidDate: "Data inválida.",
			missingType: "Escolha um tipo.",
			aleredySelected: "Já Selecionado!",
			clientNotFound: (id?: string) =>
				`Cliente ${id && "[" + id + "] "}não encontrado.`,
		},
		nav: {
			title: "Laboral",
			home: "Inicio",
			lessons: "Aulas",
			levels: "Níveis",
			contacts: "Contatos",
			tags: "Tags",
			users: "Usuários",
			collaborators: "Usuários colaboradores",
			adminUsers: "Usuários Admin",
			logout: "Sair",
			configure: "Configurações",
			about: "Sobre",
			enterprises: "Empresa",
			enterpriseAdminUsers: "Usuário RH",
			disconected: "Você está descontectado da internet",
		},
		pageLogin: {
			pagetitle: "Laboral",
			title: "Acesso",
			buttonLogin: "Entrar",
			noAccess: "Ainda não possui acesso?",
			requestAccess: "Solicite Aqui",
			recoveryPassword: "Esqueceu a senha?",
			messageFooter: "Desenvolvido de ponta a ponta pela Startapp",
			needHelp: "Precisa de ajuda?",
		},
		home: {
			welcome: "Bem vindo ao dashboard do Laboral",
			usersCollaborator: "Quantidade de usuários colaboradores cadastrados na plataforma",
			usersEnterprise: "Quantidade de usuários RH cadastrados na plataforma",
			collaborators: "Usuários Colaboradores",
			enterprise: "Usuários RH",
		},
		admin: {
			table: {
				title: "Tabela de administradores",
			},
			details: {
				title: "Detalhes do administrador",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar administrador",
				createButton: "Criar",
			},
			edit: {
				title: "editar administrador",
			},
		},
		contact: {
			table: {
				title: "Tabela de contatos",
			},
			details: {
				title: "Detalhes do contato",
			},
		},
		enterpriseAdminUsers: {
			title: "Usuário RH",
			editor: {
				getTitle: (onEditMode = true) =>
					onEditMode ? "Editar RH" : "Cadastrar RH",
			},
			edit: {
				title: (value?: string) =>
					"Edição do RH " + value,
				onClick: "RH foi editado",
				saveChanges: "Salvar",
				success: (value: string) =>
					`${value} modificado com sucesso!`,
			},
			table: {
				title: "Tabela de Usuário RH",
			},
			details: {
				title: "Detalhes de Usuário RH",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar Usuário RH",
				createButton: "Criar",
			},
		},
		level: {
			table: {
				title: "Tabela de níveis",
			},
			details: {
				title: "Detalhes do nível",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar nível",
				createButton: "Criar",
			},
			edit: {
				title: "editar nível",
				editSucess: "Nível editado com sucesso!",
				success: (level: string) =>
					`${level} modificado com sucesso!`,
			},
		},
		adminUsers: {
			title: "Administradores",
			editor: {
				getTitle: (onEditMode = true) =>
					onEditMode ? "Editar Administrador" : "Cadastrar Administrador",
			},
			edit: {
				title: (adminUserName?: string) =>
					"Edição do Administrador " + adminUserName,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (adminUserName: string) =>
					`${adminUserName} modificado com sucesso!`,
			},
			table: {
				header: {
					name: "Nome",
					phone: "Telefone",
					email: "Email",
					editDelete: "Editar / Deletar",
				},
				tableIsEmpty: "Não foi possivel encontrar mais administradores",
			},
			deleteDialogAdmin: {
				title: "Deletar Administrador",
				message: "Tem certeza que deseja deletar este administrador?",
			},
			csvHeaders: [
				{ label: "Id", key: "id" },
				{ label: "Nome", key: "name" },
				{ label: "E-mail", key: "email" },
			],
		},
		lesson: {
			create: {
				title: "Criar aula",
				createButton: "Criar",
				success: "Aula criada com sucesso!",
			},
			table: {
				title: "Tabela de Aulas",
			},
			details: {
				title: "Detalhes da aula",
			},
			delete: {
				title: "Deletar aula",
				message: "Tem certeza que deseja deletar esta aula?",
			},
			edit: {
				title: (lessonTitle?: string) => "Edição da Aula " + lessonTitle,
				saveChanges: "Salvar",
				onClick: "Aula foi editada",
				success: (lessonsTitle: string) =>
					`${lessonsTitle} modificado com sucesso!`,
			},
			availability: {
				premium: "Usuários premium",
				all: "Todos os usuários",
			},
		},
		users: {
			table: {
				title: "Tabela de usuários",
			},
			details: {
				title: "Detalhes do usuário",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar usuário",
				createButton: "Criar",
			},
			edit: {
				title: (userName?: string) => "Edição do Usuário " + userName,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (userName: string) => `${userName} modificado com sucesso!`,
			},
			blockDialogUser: {
				title: (blockUser: boolean) =>
					`${blockUser ? "Bloquear" : "Desbloquear"} usuário`,
				message: (blockUser: boolean) =>
					`Tem certeza que deseja ${
						blockUser ? "bloquear" : "desbloquear"
					} este usuário?`,
			},
		},
		collaborators: {
			table: {
				title: "Tabela de colaboradores",
				cleanPoints: "Zerar pontos",
			},
			details: {
				title: "Detalhes do colaborador",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar colaborador",
				createButton: "Criar",
			},
			edit: {
				title: (userName?: string) => "Edição do colaborador " + userName,
				onClick: "Colaborador foi editado",
				saveChanges: "Salvar",
				success: (userName: string) => `${userName} modificado com sucesso!`,
			},
			blockDialogUser: {
				title: (blockUser: boolean) =>
					`${blockUser ? "Bloquear" : "Desbloquear"} colaborador`,
				message: (blockUser: boolean) =>
					`Tem certeza que deseja ${
						blockUser ? "bloquear" : "desbloquear"
					} este colaborador?`,
			},
		},
		tags: {
			table: {
				title: "Tabela de Tags",
			},
			create: {
				title: "Criar tag",
				createButton: "Criar",
				successMessage: "Tag criada com sucesso!",
			},
			edit: {
				title: (tagName?: string) => "Edição da Tag " + tagName,
				saveChanges: "Salvar",
				onClick: "Tag foi editada",
				success: (tagName: string) =>
					`${tagName} modificado com sucesso!`,
			},
			delete: {
				title: "Confirmação de exclusão",
				message: "Deseja mesmo excluir esta tag?",
			},
		},
		enterprises: {
			table: {
				title: "Tabela de Empresas",
			},
			create: {
				title: "Criar empresas",
				createButton: "Criar",
				successMessage: "Empresas criada com sucesso!",
			},
			edit: {
				title: (name?: string) => "Edição da Empresa " + name,
				saveChanges: "Salvar",
				onClick: "Empresa foi editada",
				success: (name: string) =>
					`${name} modificado com sucesso!`,
			},
			delete: {
				title: "Confirmação de exclusão",
				message: "Deseja mesmo excluir esta empresa?",
			},
			block: {
				title: "Confirmação de bloqueio",
				message: "Deseja mesmo bloqueiar esta empresa?",
			},
			unBlock: {
				title: "Confirmação de desbloqueio",
				message: "Deseja mesmo desbloqueiar esta empresa?",
			},
		},
		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		register: {
			success: "Administrador criado com sucesso",
			successUser: "Usúario criado com sucesso",
			successLevel: "Nível criado com sucesso",
		},
		recoveryPage: {
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
	},
});

export default Strings;
