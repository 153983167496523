import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import FileCase from "../../../../components/FileCase";
import {
	Button,
	CardContent,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";
import SelectChips from "../../../../components/SelectChips";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));
	const history = useHistory();

	const onSubmitForm = async () => {
		await store.createEnterpriseAdminUser(history.goBack);
	};

	React.useEffect(() => {
		store.getEnterprisesByName("");
	}, []);

	return (
		<CardForm
			title={Strings.enterpriseAdminUsers.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmitForm}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.admin.create.createButton
					)}
				</Button>
			}
		>
			<CardContent className={classes.fields}>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("name")}
					helperText={store.fieldError.getFieldError("name")}
					variant="standard"
					type="text"
					label={Strings.fields.name}
					{...store.formController.field("name")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("email")}
					helperText={store.fieldError.getFieldError("email")}
					variant="standard"
					type="email"
					label={Strings.fields.email}
					{...store.formController.field("email")}
				/>
				{!store.selectedEnterprise && (
					<FormControl className={classes.fieldSelect}>
						<SelectChips
							label={Strings.fields.enterprise}
							objectProp="name"
							selectOptions={store.selectChipEnterprise.selectOptions}
							singleOption={store.selectChipEnterprise.singleOption}
							addOption={store.selectChipEnterprise.setSingleOption}
							removeClient={store.selectChipEnterprise.clear}
							fetchData={store.getAllEnterprisesByName}
						/>
					</FormControl>
				)}
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("password")}
					helperText={store.fieldError.getFieldError("password")}
					variant="standard"
					type="password"
					label={Strings.fields.password}
					{...store.formController.field("password")}
				/>
				{store.avatarController && (
					<FileCase
						disableActions={store.loader.isLoading}
						title={Strings.fields.image}
						{...store.avatarController.toCase()}
					/>
				)}
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
