import React from "react";
import Strings from "../../../../modules/Strings";
import CardForm from "../../../../components/CardForm";
import { PageContainer } from "../../../../components/PageContainer";
import { Box, CardContent } from "@material-ui/core";
import FileCase from "../../../../components/FileCase";
import { Button, TextField, FormControl } from "@material-ui/core";
import SelectChips from "../../../../components/SelectChips";
import { useHistory, useParams } from "react-router";
import { observer, useLocalStore } from "mobx-react-lite";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import useStyles from "./styles";

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const formLabels = Strings.fields;
	const { field } = store.formController;
	const classes = useStyles();

	React.useEffect(() => {
		store.getEnterprisesByName("");
	}, []);

	return (
		<PageContainer>
			<CardForm
				title={Strings.collaborators.edit.title(store.model?.name)}
				loading={store.loader.isLoading}
				actionsFooter={
					<Button
						disabled={
							store.userImageService
								? !!!store.userImageService.getUploader()
								: false
						}
						variant="contained"
						onClick={() => {
							store.editUser();
						}}
					>
						{Strings.collaborators.edit.saveChanges}
					</Button>
				}
			>
				<CardContent className={classes.fields}>
					<Box display="flex" flexDirection="row">
						<TextField
							disabled={store.loader.isLoading}
							error={store.fieldError.hasFieldError("name")}
							helperText={store.fieldError.getFieldError("name")}
							variant="standard"
							type="text"
							label={Strings.fields.name}
							{...field("name")}
						/>
						<TextField
							disabled={store.loader.isLoading}
							error={store.fieldError.hasFieldError("email")}
							helperText={store.fieldError.getFieldError("email")}
							variant="standard"
							type="email"
							label={Strings.fields.email}
							{...field("email")}
						/>

						<TextField
							disabled={store.loader.isLoading}
							error={store.fieldError.hasFieldError("birthdate")}
							helperText={store.fieldError.getFieldError("birthdate")}
							variant="standard"
							type="date"
							label={Strings.fields.birthdate}
							{...field("birthdate")}
						/>
					</Box>
					<Box display="flex" flexDirection="row">
						<TextField
							disabled={store.loader.isLoading}
							error={store.fieldError.hasFieldError("nick")}
							helperText={store.fieldError.getFieldError("nick")}
							variant="standard"
							type="text"
							label={Strings.fields.nick}
							{...field("nick")}
						/>
						<TextField
							disabled={store.loader.isLoading}
							error={store.fieldError.hasFieldError("cpf")}
							helperText={store.fieldError.getFieldError("cpf")}
							variant="standard"
							type="text"
							label={Strings.fields.cpf}
							{...field("cpf")}
						/>
						<TextField
							disabled={store.loader.isLoading}
							error={store.fieldError.hasFieldError("phone")}
							helperText={store.fieldError.getFieldError("phone")}
							variant="standard"
							type="text"
							label={Strings.fields.phone}
							{...field("phone")}
						/>
						{(store.selectChipEnterprise && !store.selectedEnterprise) && (
							<FormControl className={classes.fieldSelect}>
								<SelectChips
									label={Strings.fields.enterprise}
									objectProp="name"
									selectOptions={store.selectChipEnterprise.selectOptions}
									singleOption={store.selectChipEnterprise.singleOption}
									addOption={store.selectChipEnterprise.setSingleOption}
									removeClient={store.selectChipEnterprise.clear}
									fetchData={store.getEnterprisesByName}
								/>
							</FormControl>
						)}
					</Box>

					<Box maxWidth="600px">
						{store.userImageService && (
							<FileCase
								disableActions={store.loader.isLoading}
								title={formLabels.image}
								{...store.userImageService.toCase()}
							/>
						)}
					</Box>
				</CardContent>
			</CardForm>
		</PageContainer>
	);
};
export default observer(EditorView);
