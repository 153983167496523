import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import CreateView from "./CreateView";
import EditorView from "./EditorView";
import DetailsView from "./DetailsView";

interface IProps { }

const AdminUsers: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route
				exact={true}
				path="/dashboard/users"
				component={TableView}
			/>
			<Route
				exact={true}
				path="/dashboard/users/create"
				component={CreateView}
			/>
			<Route
				exact={true}
				path="/dashboard/users/edit/:id"
				component={EditorView}
			/>
			<Route
				exact={true}
				path="/dashboard/users/details/:id"
				component={DetailsView}
			/>
		</Switch>
	);
};

export default observer(AdminUsers);
