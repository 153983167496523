import { makeAutoObservable } from "mobx";

import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";

import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";

import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

import SelectChipStore from "../../../../components/SelectChips/selectChipStore";

export default class Store {
	private uiStore: UIStore;

	public loader: LoaderShelf;
	public fieldError: FieldErrorShelf<API.NewLesson>;
	public imageController: FileStore | null = null;

	public selectChipTag: SelectChipStore<API.Tag>;

	public formController = new FormStore({
		description: "",
		prerequisite: "",
		title: "",
		type: API.LessonType.compensatory,
		url: "",
		target: API.LessonTarget.general,
		premium: false,
	});

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.loader = new LoaderShelf();
		this.fieldError = new FieldErrorShelf();
		this.imageController = new FileStore(uiStore, "image");
		this.selectChipTag = new SelectChipStore<API.Tag>(
			() => API.getAllTags(0),
			this.uiStore,
			"name",
		);
		makeAutoObservable(this);
	}

	public getAllTagsByName = async (name: string) => {
		try {
			await API.getAllTagsByName(name || "");
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};

	public createLesson = async (onSuccessCallback: () => void) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			this.loader.start();
			this.fieldError.clearErrors();

			await API.createLesson({
				...this.formController.getValues(),
				image: this.imageController
					? this.imageController.getUncertainfiedImage()
					: null,
				tags: this.selectChipTag.chosenOptions,
			});

			onSuccessCallback();
			this.uiStore.showSnackbar(Strings.lesson.create.success);
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
