import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import { makeObservable, observable, runInAction } from "mobx";

export default class Store extends ModelStore<API.AdminUser> {
	private router: IRouterPusher;
	public enterpriseAdminUser: API.AdminUser | null = null;

	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(id, uiStore);
		this.router = router;
		this.getEnterpriseAdminUser(id);
		makeObservable(this, {
			enterpriseAdminUser: observable,
		});
	}

	protected async getModel(id: string) {
		return await API.getEnterpriseAdminUser(id);
	}

	public getEnterpriseAdminUser = async (id: string) => {
		const resultAdmin = await API.getEnterpriseAdminUser(id);
		runInAction(() => (this.enterpriseAdminUser = resultAdmin));
	};
}
