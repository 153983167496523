import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { grey, blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			width: "100%",
			height: "20vh",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: grey[800],
		},
		pageContent: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "rgb(235, 235, 235)",
			flexDirection: "column",
		},
		card: {
			"width": "100%",
			"maxWidth": "500px",
			"display": "flex",
			"justifyContent": "center",
			"alignItems": "center",
			"padding": theme.spacing(5, 2, 0, 2),
			"boxShadow": `0px 0px`,
			"background": "rgb(235, 235, 235)",
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiOutlinedInput-root": {
				width: "100%",
				padding: "8px",
				alignSelf: "center",
			},
		},
		label: {
			marginLeft: "10px",
			color: grey[700],
			fontSize: "15px",
			fontWeight: "bold",
		},
		input: {
			width: "100%",
			maxWidth: "287px",
			border: "none",
			borderRadius: "4px",
			backgroundColor: grey[50],
		},
		pageTitle: {
			color: grey[50],
			fontSize: "30px",
			fontWeight: "bold",
		},
		recoveryTitle: {
			marginBottom: "20px",
			color: grey[700],
			fontSize: "30px",
			fontWeight: "bold",
		},
		loginButton: {
			"paddingTop": "5px",
			"backgroundColor": grey[800],
			"borderColor": grey[800],
			"color": "#FFFFFF",
			"fontWeight": "bold",
			"width": "100%",
			"height": "45px",
			"borderRadius": "4px",
			"&:hover": {
				backgroundColor: grey[700],
			},
		},
		backButton: {
			"margin": "8px",
			"paddingTop": "5px",
			"backgroundColor": grey[100],
			"borderColor": grey[100],
			"color": "#00000",
			"fontWeight": "bold",
			"width": "100%",
			"height": "45px",
			"borderRadius": "4px",
			"&:hover": {
				backgroundColor: grey[100],
			},
		},
		tokenButton: {
			"margin": "8px",
			"paddingTop": "5px",
			"backgroundColor": grey[500],
			"borderColor": grey[500],
			"color": "#FFFFFF",
			"fontWeight": "bold",
			"width": "100%",
			"height": "45px",
			"borderRadius": "4px",
			"&:hover": {
				backgroundColor: grey[400],
			},
		},
		cardActions: {
			display: "flex",
			flexDirection: "column",
		},
	}),
);

export default useStyles;
