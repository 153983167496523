import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(1),
		},
		fieldSelect: {
			margin: theme.spacing(1),
			maxWidth: 200,
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				maxWidth: 200,
				margin: theme.spacing(1),
			},
		},
		fields: {
			"width": "100%",
			"display": "flex",
			"flexDirection": "column",
			"& .MuiTextField-root": {
				margin: theme.spacing(1),
				maxWidth: "600px",
				width: "100%",
			},
		},
	}),
);

export default useStyles;
