import api from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import UIStore from "../../../../stores/UIStore";
import AuthStore from "../../../../stores/AuthStore";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import { computed } from "mobx";
import { IFields } from "../../../../components/Select";

export default class EnterpriseTableStore extends PaginatedListStore<api.Enterprise> {
	private uiStore: UIStore;
	public loader: LoaderShelf;
	public filterParam: string;
	private options: Record<string, any>;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(uiStore, router);
		this.loader = new LoaderShelf();
		this.filterParam = "all";
		this.options = {
			all: null,
			blocked: true,
			notBlocked: false,
		};
		this.fetchPage(0);
		this.uiStore = uiStore;
	}

	protected async getDataItemsPerPage(page: number): Promise<api.Enterprise[]> {
		return await api.getAllEnterprises({ blocked: this.options[this.filterParam] }, page);
	}

	public deleteEnterprise = async (id: string) => {
		try {
			await api.deleteEnterprise(id);
			this.fetchPage(0);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};

	public changeFilterParams(filterParams: string) {
		this.filterParam = filterParams;
		this.fetchPage(0);
	}

	public block = async (id: string) => {
		this.loader.start();
		try {
			await api.blockEnterprise(id);
			this.fetchPage(0);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		} finally {
			this.loader.end();
		}
	};

	public unBlock = async (id: string) => {
		try {
			await api.unblockEnterprise(id);
			this.fetchPage(0);
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		} finally {
			this.loader.end();
		}
	};

	@computed
	public get getFilterFields(): IFields[] {
		return [
			{
				name: Strings.filter.all,
				value: "all",
			},
			{
				name: Strings.filter.blockeds,
				value: "blocked",
			},
			{
				name: Strings.filter.avaliable,
				value: "notBlocked",
			},
		];
	}
}
