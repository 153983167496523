import { makeAutoObservable } from "mobx";

import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import UIStore from "../../../../stores/UIStore";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import SelectChipStore from "../../../../components/SelectChips/selectChipStore";

interface IFormData {
	email: string;
	name: string;
	password: string;
}

export default class Store {
	private uiStore: UIStore;

	public loader: LoaderShelf;
	public fieldError: FieldErrorShelf<IFormData>;
	public selectChipEnterprise: SelectChipStore<API.Enterprise>;
	public avatarController: FileStore | null = null;
	public searchText: string = "";
	public formController: FormStore<IFormData>;
	public selectedEnterprise: API.Enterprise | null = null;

	constructor(uiStore: UIStore) {
		makeAutoObservable(this);

		this.uiStore = uiStore;

		this.loader = new LoaderShelf();
		this.fieldError = new FieldErrorShelf();
		this.avatarController = new FileStore(uiStore, "image");
		this.formController = new FormStore({
			email: "",
			name: "",
			password: "",
		});
		this.selectChipEnterprise = new SelectChipStore<API.Enterprise>(
			() => API.getAllEnterprisesByName(this.searchText),
			this.uiStore,
			"name",
		);
		this.setSelectedEnterprise();
	}

	protected setSelectedEnterprise() {
		const selectedEnterprise = localStorage.getItem("@selectedEnterprise");
		if (selectedEnterprise) {
			this.selectedEnterprise = JSON.parse(selectedEnterprise);
		}
	}

	public getEnterprisesByName = async (enterpriseName: string = "") => {
		if (this.selectedEnterprise) { return; }
		try {
			this.loader.start();
			await API.getAllEnterprisesByName(enterpriseName);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		} finally {
			this.loader.end();
		}
	}

	public getAllEnterprisesByName = async (name: string) => {
		if (this.selectedEnterprise) { return; }
		try {
			await API.getAllEnterprisesByName(name || "");
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};

	public createEnterpriseAdminUser = async (
		onSuccessCallback: () => void,
	) => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}
			if (this.selectedEnterprise || this.selectChipEnterprise.singleOption) {
				this.loader.start();
				const { email, name, password } = this.formController.getValues();
				await API.createEnterpriseAdminUsers({
					email,
					name,
					password,
					enterprise: (this.selectChipEnterprise.singleOption || this.selectedEnterprise) as API.Enterprise,
					image: this.avatarController
						? this.avatarController.getUncertainfiedImage()
						: null,
				});
				onSuccessCallback();
				this.uiStore.showSnackbar(Strings.register.success);
			} else {
				return this.uiStore.showErrorSnackbar(Strings.error.missingEnterprise);
			}
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.cleanAndAddErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
