import { makeObservable, observable, runInAction } from "mobx";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import API from "../../../../modules/API";
import SelectChipStore from "../../../../components/SelectChips/selectChipStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export interface IEditEnterpriseAdminUser {
	id: string;
	name: string;
	email: string;
	image: API.Image;
	enterprise: API.Enterprise;
}

export default class Store extends ModelStore<IEditEnterpriseAdminUser | API.AdminUser> {
	public adminUserImageService: FileStore | null = null;
	protected router: IRouteReplacer;
	public image: API.UncertainImage | null = null;
	public fieldError: FieldErrorShelf<API.EditEnterpriseAdminUser>;
	public loader: LoaderShelf;
	public selectChipEnterprise: SelectChipStore<API.Enterprise>;
	public selectedEnterprise: API.Enterprise | null = null;

	public formController = new FormStore({
		name: "",
		email: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();
		this.adminUserImageService = new FileStore(uiStore, "image");
		this.setSelectedEnterprise();

		makeObservable(this, {
			adminUserImageService: observable,
			formController: observable,
			image: observable,
		});
	}

	protected setSelectedEnterprise() {
		const selectedEnterprise = localStorage.getItem("@selectedEnterprise");
		if (selectedEnterprise) {
			this.selectedEnterprise = JSON.parse(selectedEnterprise);
		}
	}

	public getEnterprisesByName = async (enterpriseName: string = "") => {
		if (this.selectedEnterprise) { return; }
		try {
			this.loader.start();
			await API.getAllEnterprisesByName(enterpriseName);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		} finally {
			this.loader.end();
		}
	}

	protected getModel(id: string) {
		return API.getAdminUser(id);
	}

	protected afterModelFetch(model: IEditEnterpriseAdminUser) {
		runInAction(() => {
			this.adminUserImageService = new FileStore(
				this.uiStore,
				"image",
				model.image,
			);
			this.formController = new FormStore({
				name: model.name || "",
				email: model.email || "",
			});

			if (model.enterprise) {
				this.selectChipEnterprise = new SelectChipStore<API.Enterprise>(
					() => API.getAllEnterprisesByName(""),
					this.uiStore,
					"name",
				);
				this.selectChipEnterprise.singleOption = model.enterprise;
			}
		});
	}

	public getAllEnterprisesByName = async (name: string) => {
		if (this.selectedEnterprise) { return; }
		try {
			await API.getAllEnterprisesByName(name || "");
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};

	public editEnterpriseAdminUser = async (succesMessage: string) => {
		try {
			if (this.loader.isLoading) {
				throw {
					type: API.ErrorType,
					message: Strings.error.stillLoading,
				};
			}

			if (this.adminUserImageService) {
				const resultImage = this.adminUserImageService.getUncertainfiedImage();
				runInAction(() => (this.image = resultImage));
			}

			if (this.selectedEnterprise || this.selectChipEnterprise.singleOption) {
				const { email, name } = this.formController.getValues();
				const enterpriseAdminUser = await API.editEnterpriseAdminUser(this.id, {
					email,
					name,
					enterprise: (this.selectChipEnterprise.singleOption || this.selectedEnterprise) as API.Enterprise,
					image: this.image,
				});
				localStorage.setItem(
					"EnterpriseAdminUser",
					JSON.stringify({ ...enterpriseAdminUser, image: enterpriseAdminUser.image }),
				);
				this.router.replace("/dashboard/enterpriseAdminUsers");
				this.router.go(0);
				this.uiStore.showSnackbar(
					Strings.enterpriseAdminUsers.edit.success(enterpriseAdminUser.name),
				);
			} else {
				return this.uiStore.showErrorSnackbar(Strings.error.missingEnterprise);
			}
		} catch (error) {
			this.fieldError.clearErrors();
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(error.message);
			} else {
				runInAction(() => (this.error = Errors.handleError(error)));
				this.uiStore.showErrorSnackbar(this.error);
			}
		} finally {
			this.loader.end();
		}
	};
}
