import API from "../../../../modules/API";
import { makeObservable, observable, runInAction } from "mobx";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import { format, parseISO } from "date-fns";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import SelectChipStore from "../../../../components/SelectChips/selectChipStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export interface IEditUser {
	id: string;
	name: string;
	email: string;
	nick: string;
	cpf: string;
	phone: string;
	image: API.Image;
	birthdate: Date;
	enterprise: API.Enterprise;
}

export default class Store extends ModelStore<IEditUser | API.User> {
	public userImageService: FileStore | null = null;
	protected router: IRouteReplacer;
	public image: API.UncertainImage | null = null;
	public fieldError: FieldErrorShelf<API.EditUser>;
	public loader: LoaderShelf;
	public formController = new FormStore({
		email: "",
		name: "",
		nick: "",
		cpf: "",
		phone: "",
		birthdate: "",
		enterprise: "",
	});
	public user: API.User | null = null;
	public selectChipEnterprise: SelectChipStore<API.Enterprise>;

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);

		this.router = router;
		this.userImageService = new FileStore(uiStore, "image");
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();

		makeObservable(this, {
			userImageService: observable,
			formController: observable,
			image: observable,
			user: observable,
		});
	}

	public getEnterprisesByName = async (enterpriseName: string = "") => {
		if (this.selectedEnterprise) { return; }
		try {
			this.loader.start();
			await API.getAllEnterprisesByName(enterpriseName);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		} finally {
			this.loader.end();
		}
	}

	protected async getModel(id: string) {
		const userBeingEdited = await API.getCollaborator(id, this.selectedEnterprise?.id || null);
		this.user = userBeingEdited;
		return userBeingEdited;
	}

	protected afterModelFetch(model: IEditUser) {
		runInAction(() => {
			this.userImageService = new FileStore(this.uiStore, "image", model.image);
			this.formController = new FormStore({
				name: model.name || "",
				email: model.email || "",
				nick: model.nick || "",
				cpf: model.cpf || "",
				phone: model.phone || "",
				birthdate: format(model.birthdate, "yyyy-MM-dd") || "",
				enterprise: model.enterprise.id || "",
			});

			if (model.enterprise) {
				this.selectChipEnterprise = new SelectChipStore<API.Enterprise>(
					() => API.getAllEnterprisesByName(""),
					this.uiStore,
					"name",
				);
				this.selectChipEnterprise.singleOption = model.enterprise;
			}
		});
	}

	protected afterModelFetchError() {
		this.uiStore.showErrorSnackbar(this.error);
		this.router.replace("/dashboard/collaborators");
	}

	public editUser = async () => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			if (this.userImageService) {
				this.image = this.userImageService.getUncertainfiedImage();
			}

			const {
				email,
				name,
				cpf,
				nick,
				phone,
				birthdate,
			} = this.formController.getValues();

			if (!birthdate) {
				this.fieldError.addError({
					message: Strings.error.missingDate,
					field: "birthdate",
				});
				return;
			}

			this.loader.start();
			this.fieldError.clearErrors();

			if (this.selectedEnterprise || this.selectChipEnterprise.singleOption) {
				const user = await API.editCollaborator(this.id, {
					name,
					cpf,
					email,
					nick,
					phone,
					birthdate: parseISO(birthdate),
					image: this.image,
					enterprise: (this.selectChipEnterprise.singleOption || this.selectedEnterprise) as API.Enterprise,
				});
				this.router.replace("/dashboard/collaborators");
				this.router.go(0);
				this.uiStore.showSnackbar(Strings.collaborators.edit.success(user.name));
			} else {
				return this.uiStore.showSnackbar(Strings.error.missingEnterprise);
			}

		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
