import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import UserTableStore from "./store";
import { useGlobalStore } from "../../../context";
import { useHistory } from "react-router-dom";
import { Button, IconButton, TableCell, TableRow } from "@material-ui/core";
import { Visibility, Edit, Delete, Add as AddIcon } from "@material-ui/icons";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import Select from "../../../../components/Select";
import { useStyles } from "./styles";

const TableView: React.FC = () => {

	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const classes = useStyles();
	const tableStore = useLocalStore(() => new UserTableStore(uiStore, history));

	const pushToDetails = useCallback(
		(id: string) => history.push(`collaborators/details/${id}`),
		[history],
	);

	const pushToCreate = useCallback(() => history.push(`collaborators/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`collaborators/edit/${id}`),
		[history],
	);

	const cleanPoints = useCallback(
		(name?: string, id?: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.cleanPoints.message,
					title: Strings.dialogs.cleanPoints.title(name),
				},
				() => tableStore.cleanPoints(id),
			);
		},
		[uiStore, tableStore],
	);

	const handleDeleteUser = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.delete.message,
					title: Strings.dialogs.delete.title,
				},
				() => tableStore.deleteUser(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.collaborators.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.name,
					Strings.fields.email,
					Strings.fields.enterprise,
					Strings.fields.point,
					Strings.components.table.detailsAndEditAndDelete,
				]}
				headerToolbar={
					<div className={classes.headerContainer}>
						{tableStore.selectedEnterprise && (
							<Button
								variant="outlined"
								className={classes.cleanPointsButton}
								onClick={() => cleanPoints(tableStore.selectedEnterprise?.name, tableStore.selectedEnterprise?.id)}
							>
								{Strings.collaborators.table.cleanPoints}
							</Button>
						)}
						<Select
							style={{
								width: "88%",
							}}
							title={Strings.fields.pointsOrder}
							fields={tableStore.getFilterFields}
							value={tableStore.filterParam}
							onChange={(e) =>
								tableStore.changeFilterParams(e.target.value as string)
							}
						/>
					</div>
				}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>{item.email}</TableCell>
						<TableCell align={"center"}>{item?.enterprise?.name}</TableCell>
						<TableCell align={"center"}>{item.points}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
							<IconButton onClick={() => handleDeleteUser(item.id)}>
								<Delete />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={(
					<Button
						startIcon={<AddIcon />}
						onClick={pushToCreate}
					>
						{Strings.actions.create}
					</Button>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
