import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import { Button, IconButton, TableCell, TableRow } from "@material-ui/core";
import { Visibility, Edit, Delete, Add as AddIcon } from "@material-ui/icons";
import TableStore from "./store";
import { useGlobalStore } from "../../../context";
import { useHistory } from "react-router-dom";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new TableStore(uiStore, history));

	const pushToDetails = useCallback(
		(id: string) => history.push(`lessons/details/${id}`),
		[history],
	);

	const pushToCreate = useCallback(() => history.push(`lessons/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`lessons/edit/${id}`),
		[history],
	);

	const handleDeleteLesson = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.lesson.delete.message,
					title: Strings.lesson.delete.title,
				},
				() => tableStore.deleteLesson(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.lesson.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.title,
					Strings.fields.type,
					Strings.components.table.detailsAndEditAndDelete,
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.title}</TableCell>
						<TableCell align={"center"}>
							{API.translateLessonType(item.type)}
						</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<Visibility />
							</IconButton>
							<IconButton onClick={() => handleDeleteLesson(item.id)}>
								<Delete />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<Button startIcon={<AddIcon />} onClick={pushToCreate}>
						{Strings.actions.create}
					</Button>
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
