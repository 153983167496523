import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	return (
		<CardForm title={Strings.enterpriseAdminUsers.details.title}>
			{!store.enterpriseAdminUser ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow
						label={Strings.fields.name}
						value={store.enterpriseAdminUser.name}
					/>
					<DetailRow
						label={Strings.fields.email}
						value={store.enterpriseAdminUser.email}
					/>
					{store.enterpriseAdminUser.enterprise && (
						<>
							<DetailRow
								label={Strings.fields.enterprise}
								value={store.enterpriseAdminUser.enterprise.name}
							/>
							<DetailRow
								label={Strings.fields.cnpj}
								value={store.enterpriseAdminUser.enterprise.cnpj}
							/>
						</>
					)}
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
