import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Hooks
import { useMainRoutes } from "../../../hooks/useMainRoutes";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../pages/context";

// MARK: Components
import { List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from "@material-ui/core";

interface IProps {
	setOpen: (state: boolean) => void;
	open: boolean;
}

const MobileDrawer: React.FC<IProps> = (props) => {
	const { authStore } = useGlobalStore();
	const routes = useMainRoutes(authStore.adminUser);
	const history = useHistory();

	return(
		<SwipeableDrawer
			anchor={"bottom"}
			open={props.open}
			onClose={() => props.setOpen(false)}
			onOpen={() => props.setOpen(true)}
		>
			<List>
				{routes.map((route) => {
					return (
						<ListItem
							key={route.text}
							onClick={() => history.push(route.path)}
							button={true}
						>
							<ListItemIcon>
								<route.Icon />
							</ListItemIcon>
							<ListItemText primary={route.text} />
						</ListItem>
					);
				})}
			</List>
		</SwipeableDrawer>
	);
};

export default observer(MobileDrawer);
