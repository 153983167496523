import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import Home from "./Home";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import AdminUsers from "./AdminUsers";
import Collaborators from "./Collaborators";
import Users from "./Users";
import Lessons from "./Lessons";
import Tags from "./Tags";
import Levels from "./Levels";
import EnterpriseAdminUsers from "./EnterpriseAdminUsers";
import Enterprises from "./Enterprises";
import Contact from "./Contact";

export const Dashboard = () => {
	useAuthRoute();
	return (
		<MainLayout>
			<Switch>
				<Route exact={true} path="/dashboard" component={Home} />
				<Route path="/dashboard/adminUsers" component={AdminUsers} />
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/collaborators" component={Collaborators} />
				<Route path="/dashboard/lessons" component={Lessons} />
				<Route path="/dashboard/tags" component={Tags} />
				<Route path="/dashboard/levels" component={Levels} />
				<Route path="/dashboard/enterpriseAdminUsers" component={EnterpriseAdminUsers} />
				<Route path="/dashboard/enterprises" component={Enterprises} />
				<Route path="/dashboard/contacts" component={Contact} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
