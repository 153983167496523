import { makeAutoObservable } from "mobx";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import UIStore from "../../../../stores/UIStore";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";
import SelectChipStore from "../../../../components/SelectChips/selectChipStore";

export default class Store {
	private uiStore: UIStore;
	public fieldError: FieldErrorShelf<API.NewUser>;
	public loader: LoaderShelf;
	public avatarController: FileStore | null = null;
	public birthdate: Date;
	public formController = new FormStore({
		email: "",
		name: "",
		password: "",
		nick: "",
		cpf: "",
		phone: "",
		enterprise: "",
	});
	public selectedEnterprise: API.Enterprise | null = null;
	public selectChipEnterprise: SelectChipStore<API.Enterprise>;
	public searchText: string = "";

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.avatarController = new FileStore(uiStore, "image");
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();
		this.setSelectedEnterprise();
		this.selectChipEnterprise = new SelectChipStore<API.Enterprise>(
			() => API.getAllEnterprisesByName(this.searchText),
			this.uiStore,
			"name",
		);
		makeAutoObservable(this);
	}

	protected setSelectedEnterprise() {
		const selectedEnterprise = localStorage.getItem("@selectedEnterprise");
		if (selectedEnterprise) {
			this.selectedEnterprise = JSON.parse(selectedEnterprise);
		}
	}

	public getEnterprisesByName = async (enterpriseName: string = "") => {
		if (this.selectedEnterprise) { return; }
		try {
			this.loader.start();
			await API.getAllEnterprisesByName(enterpriseName);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		} finally {
			this.loader.end();
		}
	}

	public createUser = async (onSuccessCallback: () => void) => {
		const data = this.formController.getValues();

		try {
			if (!this.birthdate) {
				this.fieldError.addError({
					message: Strings.error.missingDate,
					field: "birthdate",
				});
				return;
			}
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			this.loader.start();
			this.fieldError.clearErrors();

			if (this.selectedEnterprise || this.selectChipEnterprise.singleOption) {
				await API.createCollaborator({
					name: data.name,
					cpf: data.cpf,
					email: data.email,
					password: data.password,
					nick: data.nick,
					phone: data.phone,
					birthdate: this.birthdate,
					image: this.avatarController
						? this.avatarController.getUncertainfiedImage()
						: null,
					enterprise: (this.selectChipEnterprise.singleOption || this.selectedEnterprise) as API.Enterprise,
				});
				onSuccessCallback();
				this.uiStore.showSnackbar(Strings.register.successUser, "success");
			} else {
				return this.uiStore.showSnackbar(Strings.error.missingEnterprise);
			}

		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};

	public setBirthdate = (date: Date) => {
		const today = new Date();
		if (date.getTime() <= today.getTime()) {
			this.birthdate = date;
			this.fieldError.clearErrors();
		} else {
			this.fieldError.cleanAndAddErrors([{
				message: Strings.error.invalidDate,
				field: "birthdate",
			}]);
		}
	};
}
