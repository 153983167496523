import React from "react";
import { useHistory } from "react-router";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
	FormControl,
} from "@material-ui/core";
import FileCase from "../../../../components/FileCase";
import CardForm from "../../../../components/CardForm";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import SelectChips from "../../../../components/SelectChips";

const CreateView: React.FC = () => {
	const [selectedDate, setSelectedDate] = React.useState<Date | null>();
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));
	const history = useHistory();
	const onSubmit = async () => {
		await store.createUser(() => history.goBack());
	};

	const handleDateChange = (date: Date) => {
		store.setBirthdate(date);
		setSelectedDate(store.birthdate);
	};

	React.useEffect(() => {
		store.getEnterprisesByName("");
	}, []);

	return (
		<CardForm
			title={Strings.collaborators.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmit}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.components.table.create
					)}
				</Button>
			}
		>
			<CardContent className={classes.fields}>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("name")}
					helperText={store.fieldError.getFieldError("name")}
					variant="standard"
					type="text"
					label={Strings.fields.name}
					{...store.formController.field("name")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("email")}
					helperText={store.fieldError.getFieldError("email")}
					variant="standard"
					type="email"
					label={Strings.fields.email}
					{...store.formController.field("email")}
				/>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("birthdate")}
						helperText={store.fieldError.getFieldError("birthdate")}
						disableToolbar={true}
						variant="inline"
						format="dd/MM/yyyy"
						margin="normal"
						id="date"
						name="birthdate"
						label={Strings.fields.birthdate}
						value={selectedDate}
						onChange={(date) => {
							if (date) {
								handleDateChange(date);
							}
						}}
						KeyboardButtonProps={{
							"aria-label": "change date",
						}}
					/>
				</MuiPickersUtilsProvider>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("nick")}
					helperText={store.fieldError.getFieldError("nick")}
					variant="standard"
					type="text"
					label={Strings.fields.nick}
					{...store.formController.field("nick")}
				/>
				<TextField
					variant="standard"
					type="text"
					label={Strings.fields.cpf}
					{...store.formController.field("cpf")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("password")}
					helperText={store.fieldError.getFieldError("password")}
					variant="standard"
					type="password"
					label={Strings.fields.password}
					{...store.formController.field("password")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("phone")}
					helperText={store.fieldError.getFieldError("phone")}
					variant="standard"
					type="text"
					label={Strings.fields.phone}
					{...store.formController.field("phone")}
				/>
				{!store.selectedEnterprise && (
					<FormControl className={classes.fieldSelect}>
						<SelectChips
							label={Strings.fields.enterprise}
							objectProp="name"
							selectOptions={store.selectChipEnterprise.selectOptions}
							singleOption={store.selectChipEnterprise.singleOption}
							addOption={store.selectChipEnterprise.setSingleOption}
							removeClient={store.selectChipEnterprise.clear}
							fetchData={store.getEnterprisesByName}
						/>
					</FormControl>
				)}
				{store.avatarController && (
					<FileCase
						disableActions={store.loader.isLoading}
						title={Strings.fields.image}
						{...store.avatarController.toCase()}
					/>
				)}
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
