import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import { makeObservable, observable, runInAction } from "mobx";

export default class Store extends ModelStore<API.Contact> {
	private router: IRouterPusher;
	public contact: API.Contact | null = null;

	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(id, uiStore);
		this.router = router;
		this.getContact(id);
		makeObservable(this, {
			contact: observable,
		});
	}

	protected async getModel(id: string) {
		return await API.getContact(id);
	}

	public getContact = async (id: string) => {
		this.contact = await API.getContact(id);
	};
}
