import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import ModelStore from "../../../../stores/ModelStore";
import FileStore from "../../../../stores/FileStore";
import UIStore from "../../../../stores/UIStore";
import { makeObservable, observable, runInAction } from "mobx";

export interface IDetailsLesson {
	url: string;
	title: string;
	prerequisite: string;
	description: string;
	type: API.LessonType;
	image: API.Image;
	tags: API.Tag[];
	premium: boolean;
}
export default class Store extends ModelStore<API.Lesson | IDetailsLesson> {
	private router: IRouterPusher;
	public lesson: API.Lesson | null = null;
	public lessonImageService: FileStore | null = null;
	public lessonTags: string[] = [];

	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(id, uiStore);
		this.router = router;
		this.getLesson(id);
		makeObservable(this, {
			lesson: observable,
			lessonTags: observable,
			lessonImageService: observable,
		});
	}

	protected async getModel(id: string) {
		return await API.getLesson(id);
	}

	protected afterModelFetch(model: IDetailsLesson) {
		runInAction(() => {
			this.lessonImageService = new FileStore(
				this.uiStore,
				"image",
				model.image,
			);
			model.tags.forEach((values) => {
				this.lessonTags.push(values.name);
			});
		});
	}

	public getLesson = async (id: string) => {
		const resultLesson = await API.getLesson(id);
		runInAction(() => (this.lesson = resultLesson));
	};
}
