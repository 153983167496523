import API from "../../../../modules/API";
import { makeObservable, observable, runInAction } from "mobx";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export interface IEditLevel {
	id: string;
	level: string;
	minPoints: string;
	maxPoints: string;
}

export default class Store extends ModelStore<IEditLevel | API.Level> {
	protected router: IRouteReplacer;
	public fieldError: FieldErrorShelf<API.EditLevel>;
	public loader: LoaderShelf;
	public formController = new FormStore({
		level: "",
		minPoints: "",
		maxPoints: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();
		makeObservable(this, {
			formController: observable,
		});
	}

	protected getModel(id: string) {
		return API.getLevel(id);
	}

	protected afterModelFetch(model: IEditLevel) {
		runInAction(() => {
			this.formController = new FormStore({
				level: model.level || "",
				minPoints: model.minPoints || "",
				maxPoints: model.maxPoints || "",
			});
		});
	}

	public editLevel = async (succesMessage: string) => {
		try {
			if (this.loader.isLoading) {
				throw {
					type: API.ErrorType,
					message: Strings.error.stillLoading,
				};
			}

			const { level, minPoints, maxPoints } = this.formController.getValues();

			const editLevel = await API.editLevel(this.id, {
				level: parseInt(level),
				minPoints: parseInt(minPoints),
				maxPoints: parseInt(maxPoints),
			});

			localStorage.setItem(
				"level",
				JSON.stringify({ ...editLevel }),
			);
			this.router.replace("/dashboard/levels");
			this.router.go(0);
			this.uiStore.showSnackbar(
				Strings.level.edit.success(editLevel.id),
			);
		} catch (error) {
			this.fieldError.clearErrors();
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(error.message);
			} else {
				runInAction(() => (this.error = Errors.handleError(error)));
				this.uiStore.showErrorSnackbar(this.error);
			}
		} finally {
			this.loader.end();
		}
	};
}
