import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import LevelsStore from "./store";
import { useGlobalStore } from "../../../context";
import { useHistory } from "react-router-dom";
import { Button, IconButton, TableCell, TableRow } from "@material-ui/core";
import { Visibility, Edit, Delete, Add as AddIcon } from "@material-ui/icons";
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";

const TableView: React.FC = () => {

	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new LevelsStore(uiStore, history));

	const pushToCreate = useCallback(() => history.push(`levels/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`levels/edit/${id}`),
		[history],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.level.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.level,
					Strings.fields.minPoints,
					Strings.fields.maxPoints,
					Strings.components.table.edit,
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.level}</TableCell>
						<TableCell align={"center"}>{item.minPoints}</TableCell>
						<TableCell align={"center"}>{item.maxPoints}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={(
					<Button
						startIcon={<AddIcon />}
						onClick={pushToCreate}
					>
						{Strings.actions.create}
					</Button>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
