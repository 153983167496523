import { makeObservable, observable, runInAction } from "mobx";

import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";

import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";

import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

import SelectChipStore from "../../../../components/SelectChips/selectChipStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export interface IEditLesson {
	url: string;
	title: string;
	prerequisite: string;
	description: string;
	type: API.LessonType;
	image: API.Image;
	tags: API.Tag[];
	target: API.LessonTarget;
	premium: "0" | "1";
}

export default class Store extends ModelStore<IEditLesson | API.Lesson> {
	protected router: IRouteReplacer;

	public lessonImageService: FileStore | null = null;
	public image: API.UncertainImage | null = null;

	public fieldError: FieldErrorShelf<API.EditLesson>;
	public loader: LoaderShelf;

	public selectChipTag: SelectChipStore<API.Tag>;

	public formController = new FormStore({
		description: "",
		prerequisite: "",
		title: "",
		type: API.LessonType.compensatory,
		url: "",
		target: API.LessonTarget.general,
		premium: "0",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		makeObservable(this, {
			lessonImageService: observable,
			formController: observable,
			image: observable,
		});
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();
	}

	protected getModel(id: string) {
		return API.getLesson(id);
	}

	protected afterModelFetch(model: IEditLesson) {
		runInAction(() => {
			this.lessonImageService = new FileStore(
				this.uiStore,
				"image",
				model.image,
			);
			this.formController = new FormStore({
				description: model.description || "",
				prerequisite: model.prerequisite || "",
				title: model.title || "",
				type: model.type || "",
				url: model.url || "",
				target: model.target || "",
				premium: (model.premium ? "1" : "0") || "0",
			});
			this.selectChipTag = new SelectChipStore<API.Tag>(
				() => API.getAllTags(0),
				this.uiStore,
				"name",
				model.tags,
			);
		});
	}

	public getAllTagsByName = async (name: string) => {
		try {
			await API.getAllTagsByName(name || "");
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};

	public editLesson = async () => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			this.loader.start();
			this.fieldError.clearErrors();

			if (this.lessonImageService) {
				const resultImage = this.lessonImageService.getUncertainfiedImage();
				runInAction(() => (this.image = resultImage));
			}

			const {
				description,
				prerequisite,
				url,
				type,
				title,
				target,
				premium,
			} = this.formController.getValues();

			const lesson = await API.editLesson(this.id, {
				description,
				prerequisite,
				url,
				type,
				title,
				image: this.image,
				tags: this.selectChipTag.chosenOptions,
				target,
				premium: !!parseInt(premium),
			});

			this.router.replace("/dashboard/lessons");
			this.router.go(0);
			this.uiStore.showSnackbar(Strings.lesson.edit.success(lesson.title));
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
