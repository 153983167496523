// MARK: React
import * as React from "react";

// MARK: Libs
import { observer } from "mobx-react";

// MARK: Components
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ChipList from "./ChipList";
import SingleChip from "./SingleChip";
import { debounce } from "lodash";

interface IProps<DataItemType> {
	selectOptions: DataItemType[];
	chosenOptions?: DataItemType[];
	singleOption?: DataItemType;
	objectProp?: string;
	addOption: (value: DataItemType) => void;
	fetchData: (query?: string) => void;
	removeClient: (index?: number) => void;
	className?: object;
	label?: string;
	enumTranslate?: object;
}

interface IState {
	inputValue: string;
}

@observer
export default class SelectChips<DataItemType> extends React.Component<
IProps<DataItemType>, IState
> {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: "",
		};
	}

	private addSelectOption = (option: DataItemType | null) => {
		if (option) {
			this.props.addOption(option);
		}
	};

	public handleSearch = debounce(async (value: string) => {
		this.setState({ inputValue: value });
		this.props.fetchData(value);
	}, 1000);

	public render() {

		return (
			<React.Fragment>
				<Autocomplete
					options={this.props.selectOptions}
					clearText="limpar"
					getOptionLabel={(option) => {
						if (this.props.objectProp) {
							return option[this.props.objectProp];

						}
						if (typeof option === "string" && this.props.enumTranslate) {
							return this.props.enumTranslate[String(option)];
						}
						return option;

					}}
					onChange={(e, option) => {
						this.addSelectOption(option);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							onChange={(e) => this.handleSearch(e.target.value)}
							variant="standard"
							value={this.state.inputValue}
							label={this.props.label ? this.props.label : ""}
						/>
					)}
				/>
				{this.props.chosenOptions && (
					<ChipList
						chosenOptions={this.props.chosenOptions}
						removeClient={this.props.removeClient}
						enumTranslate={this.props.enumTranslate}
						objectProp={this.props.objectProp}
					/>
				)}
				{this.props.singleOption && (
					<SingleChip
						option={this.props.singleOption}
						removeClient={this.props.removeClient}
						enumTranslate={this.props.enumTranslate}
						objectProp={this.props.objectProp}
					/>
				)}
			</React.Fragment>
		);
	}
}
