import { makeAutoObservable } from "mobx";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";
import UIStore from "../../../../stores/UIStore";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

export default class Store {
	private uiStore: UIStore;

	public fieldError: FieldErrorShelf<API.NewTag>;
	public loader: LoaderShelf;

	public avatarController: FileStore | null = null;
	public birthdate: Date;
	public formController = new FormStore({
		name: "",
		type: "",
	});

	constructor(uiStore: UIStore) {
		this.uiStore = uiStore;
		this.avatarController = new FileStore(uiStore, "image");
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();

		makeAutoObservable(this);
	}

	public createTag = async (onSuccessCallback: () => void) => {
		const data = this.formController.getValues();

		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			this.loader.start();
			this.fieldError.clearErrors();

			await API.createTag({
				name: data.name,
			});

			onSuccessCallback();
			this.uiStore.showSnackbar(Strings.tags.create.successMessage, "success");
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
