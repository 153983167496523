// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
import Store from "./store";

// MARK: Resources
import Strings from "../../modules/Strings";
import API from "../../modules/API";

// MARK: Componentes
import {
	Autocomplete,
} from "@material-ui/lab";
import {Box, TextField} from "@material-ui/core";

// MARK: Hooks
import { useStyles } from "./styles";
import usePersistState from "../../hooks/usePersistState";
import { useGlobalStore } from "../../pages/context";
import {debounce} from "lodash";
import { useHistory } from "react-router";

// MARK: Implementation
const EnterpriseSelector: React.FC = (props) => {
	const [selectedEnterprise, setSelectedEnterprise] = usePersistState<API.Enterprise | null>({
		InitialState: null,
		key: "@selectedEnterprise",
	});
	const { uiStore } = useGlobalStore();
	const classes = useStyles();
	const store = useLocalStore(() => new Store(uiStore));
	const history = useHistory();

	const handleSearch = debounce(async (value: string) => {
		store.getEnterprisesByName(value);
	}, 1000);

	const handleSelectEnterprise = (enterprise: API.Enterprise | null) => {
		setSelectedEnterprise(enterprise);
		history.go(0);
	};

	React.useEffect(() => {
		store.getEnterprisesByName("");
	}, []);

	return (
		<Box className={classes.box}>
			{(!store.enterprises && selectedEnterprise) && (
				<>
					<span className={classes.enterpriseLabel}>Empresa</span>
					<b className={classes.enterprise}>{selectedEnterprise.name}</b>
				</>
			)}
			{store.enterprises && (
				<Autocomplete
					options={store.enterprises}
					getOptionLabel={(enterprise) => enterprise.name}
					style={{ width: 300 }}
					onInputChange={(_, value) => handleSearch(value)}
					onChange={(_, value) => handleSelectEnterprise(value)}
					renderInput={(params) => <TextField {...params} label={Strings.fields.enterprise} variant="outlined" />}
					value={store.enterprises.find((item) => item.id === selectedEnterprise?.id)}
				/>
			)}
		</Box>
	);
};

export default observer(EnterpriseSelector);
