import React from "react";

import { useHistory, useParams } from "react-router";
import { observer, useLocalStore } from "mobx-react-lite";

import {
	CardContent,
	FormHelperText,
	Select,
	Button,
	TextField,
	InputLabel,
	FormControl,
} from "@material-ui/core";

import { useGlobalStore } from "../../../context";

import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";

import CardForm from "../../../../components/CardForm";
import { PageContainer } from "../../../../components/PageContainer";
import FileCase from "../../../../components/FileCase";
import SelectChips from "../../../../components/SelectChips";

import Store from "./store";

import useStyles from "./styles";

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	const { field } = store.formController;
	const classes = useStyles();

	return (
		<PageContainer>
			<CardForm
				title={Strings.lesson.edit.title(store.model?.title)}
				loading={store.loader.isLoading}
				actionsFooter={
					<Button
						variant="contained"
						onClick={() => {
							store.editLesson();
						}}
					>
						{Strings.adminUsers.edit.saveChanges}
					</Button>
				}
			>
				<CardContent>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("title")}
						helperText={store.fieldError.getFieldError("title")}
						className={classes.textField}
						variant="standard"
						type="text"
						{...field("title")}
						label={Strings.fields.title}
					/>
					<FormControl className={classes.fieldSelect}>
						<InputLabel>{Strings.fields.category}</InputLabel>
						<Select
							native={true}
							{...store.formController.field("type")}
							error={store.fieldError.hasFieldError("type")}
						>
							{API.allValuesLessonType().map((lesson, index) => (
								<option key={index} aria-selected={true} value={lesson}>
									{API.translateLessonType(lesson)}
								</option>
							))}
						</Select>
						<FormHelperText className={classes.dangerText}>
							{store.fieldError.getFieldError("type")}
						</FormHelperText>
					</FormControl>
					<FormControl className={classes.fieldSelect}>
						<InputLabel>{Strings.fields.availability}</InputLabel>
						<Select
							disabled={store.loader.isLoading}
							error={store.fieldError.hasFieldError("premium")}
							native={true}
							{...store.formController.field("premium")}
						>
							<option aria-selected={true} value={0}>
								{Strings.lesson.availability.all}
							</option>
							<option aria-selected={true} value={1}>
								{Strings.lesson.availability.premium}
							</option>
						</Select>
						<FormHelperText className={classes.dangerText}>
							{store.fieldError.getFieldError("premium")}
						</FormHelperText>
					</FormControl>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("url")}
						helperText={store.fieldError.getFieldError("url")}
						className={classes.textField}
						variant="standard"
						type="text"
						{...field("url")}
						label={Strings.fields.url}
					/>
					<FormControl className={classes.fieldSelect}>
						<InputLabel>{Strings.fields.type}</InputLabel>
						<Select
							native={true}
							{...store.formController.field("target")}
							error={store.fieldError.hasFieldError("target")}
						>
							{API.allValuesLessonTarget().map((lesson, index) => (
								<option key={index} aria-selected={true} value={lesson}>
									{API.translateLessonTarget(lesson)}
								</option>
							))}
						</Select>
						<FormHelperText className={classes.dangerText}>
							{store.fieldError.getFieldError("target")}
						</FormHelperText>
					</FormControl>
					{store.selectChipTag && (
						<FormControl className={classes.fieldSelect}>
							<SelectChips
								label="Tags"
								{...store.selectChipTag.toCase()}
								objectProp={"name"}
								fetchData={store.getAllTagsByName}
							/>
						</FormControl>
					)}
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("prerequisite")}
						helperText={store.fieldError.getFieldError("prerequisite")}
						label={Strings.fields.prerequisite}
						multiline={true}
						rowsMax={8}
						className={classes.largeField}
						{...field("prerequisite")}
					/>
					<TextField
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("description")}
						helperText={store.fieldError.getFieldError("description")}
						label={Strings.fields.description}
						multiline={true}
						rowsMax={8}
						className={classes.largeField}
						{...field("description")}
					/>
					{store.lessonImageService && (
						<FileCase
							disableActions={store.loader.isLoading}
							title={Strings.fields.image}
							{...store.lessonImageService.toCase()}
						/>
					)}
				</CardContent>
			</CardForm>
		</PageContainer>
	);
};
export default observer(EditorView);
