import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import CreateView from "./CreateView";
import EditorView from "./EditorView";

interface IProps { }

const Levels: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route
				exact={true}
				path="/dashboard/levels"
				component={TableView}
			/>
			<Route
				exact={true}
				path="/dashboard/levels/create"
				component={CreateView}
			/>
			<Route
				exact={true}
				path="/dashboard/levels/edit/:id"
				component={EditorView}
			/>
		</Switch>
	);
};

export default observer(Levels);
