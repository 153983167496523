import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import { makeObservable, observable, runInAction } from "mobx";

export default class Store {
	private router: IRouterPusher;
	public user: API.User | null = null;

	constructor(id: string, router: IRouterPusher) {
		this.router = router;
		this.getAdminUser(id);
		makeObservable(this, {
			user: observable,
		});
	}

	public getAdminUser = async (id: string) => {
		const resultAdmin = await API.getUser(id);
		runInAction(() => (this.user = resultAdmin));
	};
}
