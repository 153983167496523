import * as React from "react";
import "./style.scss";
import { observer } from "mobx-react";
import Card from "@material-ui/core/Card";
import ComponentCard from "../../../../components/ComponentCard";

interface IProps {
	stat: number;
	title: string;
	description: string;
	loading: boolean;
}

const GroupInfo: React.FC<IProps> = (props) => {
	const { stat, title, loading, description } = props;

	return (
		<div className="groupInfo">
			<Card>
				<ComponentCard
					className="cardUser"
					title={title}
					descriptionDate={description}
					descriptionLabel={stat}
					loadingLabel={loading}
				/>
			</Card>
		</div>
	);
};

export default observer(GroupInfo);
