import {
	Home as HomeIcon,
	SupervisorAccount as AdminIcon,
	Class as LessonIcon,
	Person as UserIcon,
	LocalOffer as TagIcon,
	Gamepad as LevelIcon,
	Business as EnterpriseIcon,
	RecentActors as EnterpriseAdminIcon,
	RecordVoiceOver as CollaboratorIcon,
	Contacts as ContactIcon,
} from "@material-ui/icons";
import API from "../modules/API";
import Strings from "../modules/Strings";

export function useMainRoutes(currentUser: API.AdminUser | null) {
	const commonRoutes = [
		{
			Icon: HomeIcon,
			text: Strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: CollaboratorIcon,
			text: Strings.nav.collaborators,
			path: "/dashboard/collaborators",
		},
	];

	if (currentUser && currentUser.enterprise) {
		return commonRoutes;
	}

	return commonRoutes.concat(
		{
			Icon: UserIcon,
			text: Strings.nav.users,
			path: "/dashboard/users",
		},
		{
			Icon: AdminIcon,
			text: Strings.nav.adminUsers,
			path: "/dashboard/adminUsers",
		},
		{
			Icon: EnterpriseAdminIcon,
			text: Strings.nav.enterpriseAdminUsers,
			path: "/dashboard/enterpriseAdminUsers",
		},
		{
			Icon: EnterpriseIcon,
			text: Strings.nav.enterprises,
			path: "/dashboard/enterprises",
		},
		{
			Icon: LessonIcon,
			text: Strings.nav.lessons,
			path: "/dashboard/lessons",
		},
		{
			Icon: TagIcon,
			text: Strings.nav.tags,
			path: "/dashboard/tags",
		},
		{
			Icon: LevelIcon,
			text: Strings.nav.levels,
			path: "/dashboard/levels",
		},
		{
			Icon: ContactIcon,
			text: Strings.nav.contacts,
			path: "/dashboard/contacts",
		},
	);
}
