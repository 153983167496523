import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
	},
	content: {
		display: "flex",
	},
	box: {
		paddingTop: 10,
		paddingBottom: 10,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end",
	},
	label: {
		marginBottom: 10,
	},
	enterpriseLabel: {
		fontSize: 15,
		color: "#777",
	},
	enterprise: {
		fontSize: 15,
		color: "#555",
		fontWeight: 900,
	},
}));
