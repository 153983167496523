import React, { useCallback } from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import Strings from "../../../../modules/Strings";
import EnterpriseTableStore from "./store";
import { useGlobalStore } from "../../../context";
import { useHistory } from "react-router-dom";
import { Button, IconButton, TableCell, TableRow } from "@material-ui/core";
import { Edit, Delete, Block, Add as AddIcon } from "@material-ui/icons";
import PageTableContainer from "../../../../components/PageTableContainer";
import Select from "../../../../components/Select";
import SimpleTable from "../../../../components/SimpleTable";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new EnterpriseTableStore(uiStore, history));

	const pushToCreate = useCallback(() => history.push(`enterprises/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`enterprises/edit/${id}`),
		[history],
	);

	const managerBlock = (id: string, blocked: boolean) => {
		if (blocked) {
			handleUnblock(id);
		} else {
			handleBlock(id);
		}
	};

	const handleDelete = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.enterprises.delete.message,
					title: Strings.enterprises.delete.title,
				},
				() => tableStore.deleteEnterprise(id),
			);
		},
		[uiStore, tableStore],
	);

	const handleBlock = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.enterprises.block.message,
					title: Strings.enterprises.block.title,
				},
				() => tableStore.block(id),
			);
		},
		[uiStore, tableStore],
	);

	const handleUnblock = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.enterprises.unBlock.message,
					title: Strings.enterprises.unBlock.title,
				},
				() => tableStore.unBlock(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore._loading}
				title={Strings.enterprises.table.title}
				pagination={tableStore.getPagination()}
				isHaveNexPage={tableStore._isHaveNextPage}
				rows={tableStore._items}
				headers={[
					Strings.fields.id,
					Strings.fields.name,
					Strings.fields.cnpj,
					Strings.components.table.blockAndEditAndDelete,
				]}
				headerToolbar={
					<Select
						width={200}
						title="Filtrar"
						fields={tableStore.getFilterFields}
						value={tableStore.filterParam}
						onChange={(e) =>
							tableStore.changeFilterParams(e.target.value as string)
						}
					/>
				}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>{item.cnpj}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<Edit />
							</IconButton>
							<IconButton onClick={() => managerBlock(item.id, !!item.blockedAt)}>
								<Block style={{ fill: !!item.blockedAt ? "red" : "green" }} />
							</IconButton>
							<IconButton onClick={() => handleDelete(item.id)}>
								<Delete />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<Button startIcon={<AddIcon />} onClick={pushToCreate}>
						{Strings.actions.create}
					</Button>
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
