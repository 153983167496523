import API from "../../../../modules/API";
import { makeObservable, observable, runInAction } from "mobx";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export default class Store extends ModelStore<API.EditTag | API.Tag> {
	protected router: IRouteReplacer;
	public image: API.UncertainImage | null = null;
	public fieldError: FieldErrorShelf<API.EditTag>;
	public loader: LoaderShelf;
	public formController = new FormStore({
		name: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);

		this.router = router;
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();

		makeObservable(this, {
			formController: observable,
			image: observable,
		});
	}

	protected getModel(id: string) {
		return API.getTag(id);
	}

	protected afterModelFetch(model: API.EditTag) {
		runInAction(() => {
			this.formController = new FormStore({
				name: model.name || "",
			});
		});
	}

	protected afterModelFetchError() {
		this.uiStore.showErrorSnackbar(this.error);
		this.router.replace("/dashboard/tags");
	}

	public editTag = async () => {
		try {
			if (this.loader.isLoading) {
				Errors.create.stillLoading();
			}

			const { name } = this.formController.getValues();

			this.loader.start();
			this.fieldError.clearErrors();

			const tag = await API.editTag(this.id, {
				name,
			});

			this.router.replace("/dashboard/tags");
			this.router.go(0);
			this.uiStore.showSnackbar(Strings.tags.edit.success(tag.name));
		} catch (error) {
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(JSON.parse(error.message));
			} else {
				this.uiStore.showErrorSnackbar(Errors.handleError(error));
			}
		} finally {
			this.loader.end();
		}
	};
}
