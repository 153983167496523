import API from "../../../../modules/API";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import { makeObservable, observable, runInAction } from "mobx";

export default class Store {
	private router: IRouterPusher;
	public user: API.User | null = null;
	public selectedEnterprise: API.Enterprise | null = null;

	constructor(id: string, router: IRouterPusher) {
		this.router = router;
		this.setSelectedEnterprise();
		this.getCollaborator(id);
		makeObservable(this, {
			user: observable,
		});
	}

	public getCollaborator = async (id: string) => {
		const resultAdmin = await API.getCollaborator(
			id,
			this.selectedEnterprise && this.selectedEnterprise.id,
		);
		runInAction(() => (this.user = resultAdmin));
	};

	protected setSelectedEnterprise() {
		const selectedEnterprise = localStorage.getItem("@selectedEnterprise");
		if (selectedEnterprise) {
			this.selectedEnterprise = JSON.parse(selectedEnterprise);
		}
	}
}
