import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import {
	Button,
	CardContent,
	CircularProgress,
	Select,
	TextField,
	InputLabel,
	FormControl,
	FormHelperText,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";
import FileCase from "../../../../components/FileCase";
import SelectChips from "../../../../components/SelectChips";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));

	const history = useHistory();
	const onSubmit = async () => {
		await store.createLesson(() => history.goBack());
	};

	return (
		<CardForm
			title={Strings.lesson.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmit}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.lesson.create.createButton
					)}
				</Button>
			}
		>
			<CardContent>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("title")}
					helperText={store.fieldError.getFieldError("title")}
					className={classes.textField}
					variant="standard"
					type="text"
					label={Strings.fields.title}
					{...store.formController.field("title")}
				/>
				<FormControl className={classes.fieldSelect}>
					<InputLabel>{Strings.fields.category}</InputLabel>
					<Select
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("type")}
						native={true}
						{...store.formController.field("type")}
					>
						{API.allValuesLessonType().map((lesson, index) => (
							<option key={index} aria-selected={true} value={lesson}>
								{API.translateLessonType(lesson)}
							</option>
						))}
					</Select>
					<FormHelperText className={classes.dangerText}>
						{store.fieldError.getFieldError("type")}
					</FormHelperText>
				</FormControl>
				<FormControl className={classes.fieldSelect}>
					<InputLabel>{Strings.fields.availability}</InputLabel>
					<Select
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("premium")}
						native={true}
						{...store.formController.field("premium")}
					>
						<option aria-selected={true} value={0}>
							{Strings.lesson.availability.all}
						</option>
						<option aria-selected={true} value={1}>
							{Strings.lesson.availability.premium}
						</option>
					</Select>
					<FormHelperText className={classes.dangerText}>
						{store.fieldError.getFieldError("premium")}
					</FormHelperText>
				</FormControl>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("url")}
					helperText={store.fieldError.getFieldError("url")}
					className={classes.textField}
					variant="standard"
					type="text"
					label={Strings.fields.url}
					{...store.formController.field("url")}
				/>
				<FormControl className={classes.fieldSelect}>
					<InputLabel>{Strings.fields.type}</InputLabel>
					<Select
						disabled={store.loader.isLoading}
						error={store.fieldError.hasFieldError("target")}
						native={true}
						{...store.formController.field("target")}
					>
						{API.allValuesLessonTarget().map((target, index) => (
							<option key={index} aria-selected={true} value={target}>
								{API.translateLessonTarget(target)}
							</option>
						))}
					</Select>
					<FormHelperText className={classes.dangerText}>
						{store.fieldError.getFieldError("target")}
					</FormHelperText>
				</FormControl>
				{store.selectChipTag && (
					<FormControl className={classes.fieldSelect}>
						<SelectChips
							label="Tags"
							{...store.selectChipTag.toCase()}
							fetchData={store.getAllTagsByName}
							objectProp={"name"}
						/>
					</FormControl>
				)}
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("prerequisite")}
					helperText={store.fieldError.getFieldError("prerequisite")}
					className={classes.largeField}
					label={Strings.fields.prerequisite}
					multiline={true}
					rowsMax={8}
					{...store.formController.field("prerequisite")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("description")}
					helperText={store.fieldError.getFieldError("description")}
					className={classes.largeField}
					{...store.formController.field("description")}
					label={Strings.fields.description}
					multiline={true}
					rowsMax={8}
				/>
				{store.imageController && (
					<FileCase
						disableActions={store.loader.isLoading}
						title={Strings.fields.image}
						{...store.imageController.toCase()}
					/>
				)}
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
