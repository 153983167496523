import api from "../../../modules/API";
import Strings from "../../../modules/Strings";
import UIStore from "../../../stores/UIStore";
import LoaderShelf from "../../../shelves/LoaderShelf";
import { makeAutoObservable } from "mobx";

export default class HomeStore {
	private uiStore: UIStore;
	public homeData: api.HomeData | null = null;
	public loader: LoaderShelf;
	public selectedEnterprise: api.Enterprise | null = null;

	constructor(uiStore: UIStore) {
		this.loader = new LoaderShelf();
		this.uiStore = uiStore;
		this.setSelectedEnterprise();
		this.getHomeData(this.selectedEnterprise && this.selectedEnterprise.id);
		makeAutoObservable(this);
	}

	protected setSelectedEnterprise() {
		const selectedEnterprise = localStorage.getItem("@selectedEnterprise");
		if (selectedEnterprise) {
			this.selectedEnterprise = JSON.parse(selectedEnterprise);
		}
	}

	public getHomeData = async (id: string | null) => {
		this.loader.start();
		try {
			this.homeData = await api.getHomeData(id);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		} finally {
			this.loader.end();
		}
	};
}
