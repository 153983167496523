// MARK: React
import { makeAutoObservable, runInAction } from "mobx";

// MARK: Modules
import Strings from "../../modules/Strings";

// MARK: Stores
import UIStore from "../../stores/UIStore";

export default class SelectChipStore<itemType> {
	public selectOptions: itemType[] = [];
	public chosenOptions: itemType[] = [];
	public singleOption: itemType | null = null;
	public propToFilter: string;
	protected uiStore: UIStore;

	constructor(
		getOptions: () => Promise<itemType[]>,
		uiStore: UIStore,
		propToFilter: string,
		oldClients?: itemType[],
	) {
		this.propToFilter = propToFilter;
		this.getTags(getOptions);
		this.uiStore = uiStore;
		oldClients ? (this.chosenOptions = oldClients) : (this.chosenOptions = []);
		makeAutoObservable(this);
	}

	public setOption = (value: itemType) => {
		const tagIndex = this.chosenOptions.find(
			(element) => element[this.propToFilter] === value[this.propToFilter],
		);
		if (!tagIndex && value) {
			this.chosenOptions.push(value);
		} else {
			this.uiStore.showSnackbar(Strings.error.aleredySelected, "info");
		}
	};

	public setSingleOption = (value: itemType) => {
		this.singleOption = value;
	};

	public getTags = async (getOptions: () => Promise<itemType[]>) => {
		const resultTags = await getOptions();
		runInAction(() => {
			this.selectOptions = resultTags;
		});
	};

	public clear = async (index: number) => {
		this.chosenOptions.splice(index, 1);
		this.singleOption = null;
	};

	public toCase = () => {
		return {
			selectOptions: this.selectOptions,
			chosenOptions: this.chosenOptions,
			addOption: this.setOption,
			removeClient: this.clear,
		};
	};
}
