import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";
import { format } from "date-fns";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, history));

	return (
		<CardForm title={Strings.users.details.title}>
			{!store.user ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow label={Strings.fields.name} value={store.user.name} />
					<DetailRow label={Strings.fields.nick} value={store.user.nick} />
					<DetailRow label={Strings.fields.email} value={store.user.email} />
					{store.user.cpf
						&& (
							<DetailRow label={Strings.fields.cpf} value={store.user.cpf} />
						)}
					{store.user.phone
						&& (
							<DetailRow label={Strings.fields.phone} value={store.user.phone} />
						)}
					{store.user.birthdate && (
						<DetailRow
							label={Strings.fields.birthdate}
							value={format(store.user.birthdate, "dd/MM/yyyy")}
						/>
					)}
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
