import React from "react";
import "./style.scss";
import { observer, useLocalStore } from "mobx-react-lite";
import Strings from "../../../modules/Strings";
import { PageContainer } from "../../../components/PageContainer";
import { Box } from "@material-ui/core";
import HomeStore from "./store";
import { useGlobalStore } from "../../../pages/context";
import GroupInfo from "./GroupInfo";

const Home = observer(() => {
	const { uiStore } = useGlobalStore();
	const homeStore = useLocalStore(() => new HomeStore(uiStore));

	return (
		<PageContainer>
			<Box className="welcomeContainer" display="flex" flexDirection="column">
				{Strings.home.welcome}
			</Box>
			{homeStore.homeData && (
				<Box className="cardStats">
					<GroupInfo
						description={Strings.home.usersCollaborator}
						loading={homeStore.loader.isLoading}
						stat={homeStore.homeData.numberOfUsersCollaborator || 0}
						title={Strings.home.collaborators}
					/>
					<GroupInfo
						description={Strings.home.usersEnterprise}
						loading={homeStore.loader.isLoading}
						stat={homeStore.homeData.numberOfUsersEnterprise || 0}
						title={Strings.home.enterprise}
					/>
				</Box>
			)}
		</PageContainer>
	);
});

export default Home;
