// MARK: React
import * as React from "react";

// MARK: Libs
import { observer } from "mobx-react";

// MARK: Components
import { Box, Chip } from "@material-ui/core";

interface IProps<DataItemType> {
	option: DataItemType;
	objectProp?: string;
	removeClient: () => void;
	enumTranslate?: object;
}

@observer
export default class SingleChip<DataItemType> extends React.Component<
	IProps<DataItemType>
> {
	public render() {
		const {
			option,
			objectProp,
			removeClient,
			enumTranslate,
		} = this.props;

		const labelChipsCase = (value: DataItemType) => {
			if (typeof value === "string" && enumTranslate) {
				return enumTranslate[value as string];
			} else {
				return value;
			}
		};

		return (
			<Box
				style={{
					width: "100%",
					maxWidth: "200px",
					marginTop: "1px",
				}}
			>
					<Chip
						variant="outlined"
						label={objectProp ? option[objectProp] : labelChipsCase(option)}
						style={{ margin: 1 }}
						onDelete={() => removeClient()}
					/>
			</Box>
		);
	}
}
