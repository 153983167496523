import API from "../../modules/API";
import { makeObservable, observable } from "mobx";
import LoaderShelf from "../../shelves/LoaderShelf";
import UIStore from "../../stores/UIStore";
import Strings from "../../modules/Strings";
import AuthStore from "../../stores/AuthStore";

export default class Store {
	public loader: LoaderShelf;
	public enterprises: API.Enterprise[] | null = null;
	protected uiStore: UIStore;
	private authStore: AuthStore;

	constructor(uiStore: UIStore) {
		this.loader = new LoaderShelf();
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;

		makeObservable(this, {
			enterprises: observable,
		});
	}

	public getEnterprisesByName = async (enterpriseName: string = "") => {
		if (this.authStore.adminUser?.enterprise) { return; }
		try {
			this.loader.start();
			this.enterprises = await API.getAllEnterprisesByName(enterpriseName);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		} finally {
			this.loader.end();
		}
	}
}
