import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import LinearProgress from "../../../../components/LinearProgress";

const DetailsView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	return (
		<CardForm title={Strings.contact.details.title}>
			{!store.contact ? (
				<LinearProgress />
			) : (
				<>
					<DetailRow
						label={Strings.fields.name}
						value={store.contact.name}
					/>
					<DetailRow
						label={Strings.fields.email}
						value={store.contact.email}
					/>
					<DetailRow
						label={Strings.fields.phone}
						value={store.contact.phone}
					/>
					<DetailRow
						label={Strings.fields.numberOfEmployees}
						value={store.contact.numberOfEmployees}
					/>
				</>
			)}
		</CardForm>
	);
};
export default observer(DetailsView);
