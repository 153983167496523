import API from "../../../../modules/API";
import { makeObservable, observable, runInAction } from "mobx";
import Strings from "../../../../modules/Strings";
import { Errors } from "../../../../modules/Errors";
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";
import FieldErrorShelf from "../../../../shelves/FieldErrorShelf";
import LoaderShelf from "../../../../shelves/LoaderShelf";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export interface IEditAdminUser {
	id: string;
	name: string;
	email: string;
	image: API.Image;
}

export default class Store extends ModelStore<IEditAdminUser | API.AdminUser> {
	public adminUserImageService: FileStore | null = null;
	protected router: IRouteReplacer;
	public image: API.UncertainImage | null = null;
	public fieldError: FieldErrorShelf<API.EditAdminUser>;
	public loader: LoaderShelf;
	public formController = new FormStore({
		name: "",
		email: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		this.fieldError = new FieldErrorShelf();
		this.loader = new LoaderShelf();
		this.adminUserImageService = new FileStore(uiStore, "image");
		makeObservable(this, {
			adminUserImageService: observable,
			formController: observable,
			image: observable,
		});
	}

	protected getModel(id: string) {
		return API.getAdminUser(id);
	}

	protected afterModelFetch(model: IEditAdminUser) {
		runInAction(() => {
			this.adminUserImageService = new FileStore(
				this.uiStore,
				"image",
				model.image,
			);
			this.formController = new FormStore({
				name: model.name || "",
				email: model.email || "",
			});
		});
	}

	public editAdminUser = async (succesMessage: string) => {
		try {
			if (this.loader.isLoading) {
				throw {
					type: API.ErrorType,
					message: Strings.error.stillLoading,
				};
			}

			if (this.adminUserImageService) {
				const resultImage = this.adminUserImageService.getUncertainfiedImage();
				runInAction(() => (this.image = resultImage));
			}

			const { email, name } = this.formController.getValues();

			const adminUser = await API.editAdminUser(this.id, {
				email,
				name,
				image: this.image,
			});

			localStorage.setItem(
				"adminUser",
				JSON.stringify({ ...adminUser, image: adminUser.image }),
			);
			this.router.replace("/dashboard/adminUsers");
			this.router.go(0);
			this.uiStore.showSnackbar(
				Strings.adminUsers.edit.success(adminUser.name),
			);
		} catch (error) {
			this.fieldError.clearErrors();
			if (error.type === API.ErrorType.Validation) {
				this.fieldError.addErrors(error.message);
			} else {
				runInAction(() => (this.error = Errors.handleError(error)));
				this.uiStore.showErrorSnackbar(this.error);
			}
		} finally {
			this.loader.end();
		}
	};
}
