import React from "react";
import useStyles from "./styles";
import { observer, useLocalStore } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useGlobalStore } from "../../../context";
import Store from "./store";
import Strings from "../../../../modules/Strings";
import FileCase from "../../../../components/FileCase";
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
} from "@material-ui/core";
import CardForm from "../../../../components/CardForm";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const store = useLocalStore(() => new Store(uiStore));
	const history = useHistory();
	const onSubmitForm = async () => {
		await store.createAdminUser(history.goBack);
	};

	return (
		<CardForm
			title={Strings.admin.create.title}
			actionsFooter={
				<Button
					disabled={store.loader.isLoading}
					type="submit"
					variant="contained"
					color="default"
					onClick={onSubmitForm}
				>
					{store.loader.isLoading ? (
						<CircularProgress />
					) : (
						Strings.admin.create.createButton
					)}
				</Button>
			}
		>
			<CardContent className={classes.fields}>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("name")}
					helperText={store.fieldError.getFieldError("name")}
					variant="standard"
					type="text"
					label={Strings.fields.name}
					{...store.formController.field("name")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("email")}
					helperText={store.fieldError.getFieldError("email")}
					variant="standard"
					type="email"
					label={Strings.fields.email}
					{...store.formController.field("email")}
				/>
				<TextField
					disabled={store.loader.isLoading}
					error={store.fieldError.hasFieldError("password")}
					helperText={store.fieldError.getFieldError("password")}
					variant="standard"
					type="password"
					label={Strings.fields.password}
					{...store.formController.field("password")}
				/>
				{store.avatarController && (
					<FileCase
						disableActions={store.loader.isLoading}
						title={Strings.fields.image}
						{...store.avatarController.toCase()}
					/>
				)}
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
