import { createStyles, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			marginTop: theme.spacing(2),
		},
		textEllipsis: {
			whiteSpace: "nowrap",
			width: "28vw",
			justifyContent: "center",
			fontSize: "14px",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		headerContainer: {
			width: "60%",
			display: "flex",
			justifyContent: "flex-end",
		},
		cleanPointsButton: {
			"backgroundColor": grey[600],
			"borderColor": grey[600],
			"marginTop": "10px",
			"color": "#FFFFFF",
			"fontWeight": "bold",
			"marginRight": "15px",
			"width": "22%",
			"height": "40px",
			"borderRadius": "4px",
			"&:hover": {
				backgroundColor: grey[700],
			},
		},
	}),
);
